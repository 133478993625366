import { createUseStyles } from 'react-jss';

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ ratingScale }: ThemeProps) => ({
    ratingButton: {
        ...ratingScale.ratingButton,
        '&:active': {
            ...ratingScale.ratingButtonHovered,
        }
    },
    ratingButtonEmoji: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '@media screen and (max-width: 320px)': { // iPhone 4 resolution
        ratingButton: {
            width: '52px',
        },
    },
    '@media screen and (max-width: 280px)': { // Galaxy Fold resolution
        ratingButton: {
            width: '42px'
        }
    },
    ratingButtonSelected: {
        ...ratingScale.ratingButtonSelected,
    },

    '@media (hover: hover)': {
        ratingButton: {
            "&:hover": {
                ...ratingScale.ratingButtonHovered,
            },
        },
        ratingButtonSelected: {
            "&:hover": {
                ...ratingScale.ratingButtonSelected,
            }
        },
    }
}));
