import React from 'react';

import { ModalWindow } from 'components/modal-window';
import { DottedLoader } from 'components/dotted-loader';

import { useStyles } from './styles';

type LoadingModalWindowProps = {
    loading: boolean;
}

export const LoadingModalWindow = ({ loading }: LoadingModalWindowProps): JSX.Element => {
    const { modalWindowLoading } = useStyles();

    return (
        <>
            {loading &&
                <ModalWindow wrapperClassName={modalWindowLoading}>
                    <DottedLoader width={48} height={48}/>
                </ModalWindow>}
        </>
    );
};
