import i18n from '../translations/i18n';
import { MultipleLocalStorageKeys } from 'constants/local-storage';

export const UNIT_SYSTEM = {
    imperial: 'imperial',
    metric: 'metric',
};

export const TABS = [
    { key: UNIT_SYSTEM.imperial, label: i18n.t('body-height-weight-tab-imperial') },
    { key: UNIT_SYSTEM.metric, label: i18n.t('body-height-weight-tab-metric') },
];

export const getUnitSystem = () =>
    localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem);
