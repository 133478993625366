import { Palette } from '../index';
import { ChoiceButtonStyles } from './choice-button';
import { palette } from 'themes/default/palette';

export type MultiChoiceButtonType = {
    multiChoiceButton: {
        width: string,
        minWidth: string,
        height: string,
        padding: string,
        border: string,
        margin: string,
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: string,
        backgroundColor: string,
        borderRadius: string,
        borderColor: string,
        color: string,
    },
    multiChoiceButtonSelected: {
        border: string,
        background: Palette | string,
        color: Palette | string,
    },
    multiChoiceButtonDisabled?: {
        color: string;
        background: string;
        cursor: string;
        pointerEvents: string;
    }
    multiChoiceButtonHovered?: {
        background: string,
        color?: string,
    },
}

export const MultiChoiceButtonStyles: MultiChoiceButtonType = {
    multiChoiceButton: {
        ...ChoiceButtonStyles.choiceButton,
        padding: '8px 16px'
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: 'none',
        backgroundColor: palette.dark_black,
        borderRadius: '2px',
        borderColor: 'inherit',
        color: palette.white
    },
    multiChoiceButtonSelected: {
        ...ChoiceButtonStyles.choiceButtonSelected,
    },
    multiChoiceButtonHovered: ChoiceButtonStyles.choiceButtonHovered,
    multiChoiceButtonDisabled: {
        color: palette.disabled_grey,
        background: palette.disabled_black,
        cursor: 'none',
        pointerEvents: 'none',
    },
};
