import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const PullUps = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PullUps];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('pull-ups-title'), value: t(answer.value) },
        });
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const choices: ChoiceType[] = [
        { key: 'none', value: 'pull-ups-option-none' },
        { key: 'little', value: 'pull-ups-option-little' },
        { key: 'normal', value: 'pull-ups-option-normal' },
        { key: 'more', value: 'pull-ups-option-more' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('pull-ups-title')}
            </Typography>

            {choices.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={t(v.value)}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
