import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const BodyPart = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyPart];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'all',
            value: t('body-part-option-full-body'),
            image: 'webp/body-part/full.webp',
        },
        {
            key: 'chest',
            value: t('body-part-option-chest'),
            image: 'webp/body-part/chest.webp',
        },
        {
            key: 'arms',
            value: t('body-part-option-arms'),
            image: 'webp/body-part/arm.webp',
        },
        {
            key: 'abs',
            value: t('body-part-option-abs'),
            image: 'webp/body-part/abs.webp',
        },
        {
            key: 'legs',
            value: t('body-part-option-legs'),
            image: 'webp/body-part/legs.webp',
        },
        {
            key: 'back',
            value: t('body-part-option-back'),
            image: 'webp/body-part/backpart.webp',
        },
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-part-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('body-part-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('body-part-subtitle')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    disabled={pageValue[0] === 'all' && v.key !== 'all'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
