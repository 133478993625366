import React, { FC } from 'react';

import { TypographyVariants } from 'constants/typography-variants';

import { isNumber } from 'helpers';
import { Typography } from '../typography';

import { useStyles } from './styles';

export interface ThinProgressBarProps {
    progress: { percents: number; label: string };
    className?: string;
}

export const ThinProgressBar: FC<ThinProgressBarProps> = ({ progress, className }) => {
    const { wrapperBar, progressBar, labelStyles } = useStyles();
    const width = `calc(100% * ${progress.percents / 100})`;

    return (
        <>
            {isNumber(progress.percents) && (
                <div className={`${wrapperBar} ${className}`}>
                    <div className={progressBar} style={{ width }}></div>

                    {progress.label && (
                        <Typography variant={TypographyVariants.span} className={labelStyles}>
                            {progress.label}
                        </Typography>
                    )}
                </div>
            )}
        </>
    );
};
