import { Palette } from '../index';

export type TipPageType = {
    tipPageWrapper: {
        display: string,
        alignItems: string,
        flexDirection: string,
        background: Palette | string,
        position: string,
        height: string,
        width: string,
        padding: string,
    },
    tipPageIcon: {
        margin: string,
        minHeight: string,
    },
}

export const TipPageStyles: TipPageType = {
    tipPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#010101',
        position: 'absolute',
        height: 'calc(100vh - 72px)', // @description: 72px - header height
        width: '100vw',
        padding: '0 16px',
    },
    tipPageIcon: {
        margin: '48px 0',
        minHeight: '64px',
    },
};
