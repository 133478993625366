import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '560px',
        padding: '0 16px 32px',
        margin: '0 auto',
    },
    title: {},
    description: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0 0 24px'
    },
    image: {
        width: '100%',
        maxWidth: '358px'
    },
    offerWrapper: {
        margin: '24px 0 0',

        '& h1': {
            textAlign: 'left',
            margin: 'unset',
            padding: '0 0 16px',
            borderBottom: '1px solid #C5C5D1'
        },

        '& h2': {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
        }
    },
    calculationBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',

        '&:nth-of-type(3)': {
            paddingBottom: '16px',
            borderBottom: '1px solid #C5C5D1'
        },

        '&:nth-of-type(4)': {
            '& h3': {
                lineHeight: '24px',
                fontWeight: 600
            }
        },

        '& h3': {
            fontSize: '16px',
            margin: 'unset'
        },

        '&:last-child': {
            marginBottom: '24px',
        },
    },
    savingPercentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px'
    },
    savingPercent: {
        background: '#10352A',
        borderRadius: '100px',
        padding: '4px 10px',

        '& h3': {
            fontWeight: 600,
            color: '#9BDCC9',
        }
    },
    blue: {
        color: '#6AABEC'
    },
    totalWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',

        '& h3:last-child': {
            fontWeight: 400,
            color: '#5E626B',
            textDecoration: 'line-through'
        }
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '24px 16px',
        background: '#373739',
        borderRadius: '8px',
        marginBottom: '4px',

        '& ul': {
            listStyle: 'disc',
            listStylePosition: 'outside',
            paddingLeft: '16px',

            '& li': {

                '& h3': {
                    textAlign: 'left',
                    margin: '0 0 12px'
                },

                '&:last-child h3': {
                    margin: 'unset'
                }
            }
        }
    },
    upgradePlanButton: {
        ...nextButton.nextButton,
        height: '100%',

        '&:active': {
            ...nextButton.nextButtonFocus,
        }
    },
    continueWithTrialButton: {
        ...nextButton.nextButton,
        height: '100%',
        background: 'none',
        color: '#EB731A',
        padding: 0,
        margin: '7px 0 0'
    },
    '@media screen and (min-width: 768px)': {
        pageWrapper: {
            padding: '0 0 40px',
        },
        title: {
            lineHeight: '32px',
            margin: '80px 0 16px'
        },
        description: {
            fontSize: '18px',
            lineHeight: '26px',
            margin: '0 0 40px'
        },
        offerWrapper: {
            margin: '40px 0',

            '& h1': {
                lineHeight: '32px'
            },

            '& h2': {
                fontSize: '18px',
                lineHeight: '26px'
            },
        },
        calculationBlock: {

            '&:nth-of-type(4)': {

                '& h3': {
                    lineHeight: '20px',
                }
            },

            '& h3': {
                fontSize: '18px',
                lineHeight: '20px'
            },
        },
        infoBlock: {
            marginBottom: '20px',

            '& ul': {

                '& li': {

                    '& h3': {
                        fontSize: '16px',
                        lineHeight: '20px'
                    }
                }
            }
        },
        upgradePlanButton: {
            minHeight: '56px',

            '& span': {
                lineHeight: '24px'
            }
        },
        continueWithTrialButton: {
            margin: '12px 0 0',

            '& span': {
                lineHeight: '24px'
            }
        },
    },
    '@media (hover: hover)': {
        upgradePlanButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
