import { InputType, InputStyles } from './default/input';
import { ButtonType, ButtonStyles } from './default/button';
import { NextButtonStyles, NextButtonType } from './default/next-button';
import { ChoiceButtonType, ChoiceButtonStyles } from './default/choice-button';
import { MultiChoiceButtonStyles, MultiChoiceButtonType } from './default/multichoice-button';
import { TypographyType, TypographyStyles } from './default/typography';
import { HeaderStyles, HeaderType } from './default/header';
import { ProgressBarStyles, ProgressBarType } from './default/progress-bar';
import { TipPageStyles, TipPageType } from "./default/tip-page";
import { RatingScaleStyles, RatingScaleType } from './default/rating-scale';
import { BackArrowButtonStyles, BackArrowButtonType } from './default/back-arrow-button';

export type Palette = Record<string, string>

export type ThemeProps = {
    input: InputType,
    button: ButtonType,
    nextButton: NextButtonType,
    choiceButton: ChoiceButtonType,
    multiChoiceButton: MultiChoiceButtonType,
    backArrowButton: BackArrowButtonType,
    header: HeaderType,
    progressBar: ProgressBarType,
    tipPage: TipPageType,
    ratingScale: RatingScaleType,
    typography: {
        h1: TypographyType,
        h2: TypographyType,
        h3: TypographyType,
        h4: TypographyType,
        h5?: TypographyType,
        h6?: TypographyType,
        button: Partial<TypographyType>,
        choiceButton: Partial<TypographyType>,
        multiChoiceButton: Partial<TypographyType>,
        inputStartAdornment: Partial<TypographyType>,
        inputEndAdornment: Partial<TypographyType>,
        inputLabel: Partial<TypographyType>,
        inputLabelActive: Partial<TypographyType>,
        inputLabelError: Partial<TypographyType>,
        validatedInputError: Partial<TypographyType>,
        tipPage: Partial<TypographyType>,
        ratingButton: Partial<TypographyType>,
        ratingGradationInfo: Partial<TypographyType>,
        p?: {
            color: Palette | string,
            fontSize: string | number,
            lineHeight: string | number,
            fontWeight: number,
            margin: string | number,
            letterSpacing: string | number,
        }
    }
}

export type Theme = {
    theme: ThemeProps,
    overwrite?: Partial<ThemeProps>,
}

export const defaultTheme: ThemeProps = {
    input: InputStyles,
    button: ButtonStyles,
    nextButton: NextButtonStyles,
    choiceButton: ChoiceButtonStyles,
    multiChoiceButton: MultiChoiceButtonStyles,
    backArrowButton: BackArrowButtonStyles,
    typography: TypographyStyles,
    header: HeaderStyles,
    progressBar: ProgressBarStyles,
    tipPage: TipPageStyles,
    ratingScale: RatingScaleStyles,
};
