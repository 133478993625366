import { createUseStyles } from "react-jss";
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    modalWindowWrapper: {
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       position: 'fixed',
       flexDirection: 'column',
       top: 0,
       left: 0,
       right: 0,
       bottom: 0,
       background: 'rgba(55, 55, 57, 0.7)',
       backdropFilter: 'blur(3px)',
       zIndex: 201, // because of PayPal button
       width: '100%',
   },
    modalWindowForm: {
        margin: '0 8px',
        maxWidth: '359px',
        maxHeight: '90%',
        overflowY: 'auto',
        position: 'relative',
        background: `${palette.dark_black}`,

        '& *': {
            boxSizing: 'border-box'
        },
        '& iframe': {
            height: '48px !important',
        },
        borderRadius: 16,
        padding: '48px 24px 32px',
        boxSizing: 'border-box',
        overflowX: 'hidden'
    },
    close: {
        position: 'absolute',
        top: 16,
        right: 26,
        cursor: 'pointer'
    },
    '@media screen and (max-width: 280px)': { // Galaxy Fold resolution
        modalWindowForm: {
            maxWidth: '250px'
        }
    },
});
