import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoutePath } from './route-path.constant';

import { BodyAge } from 'pages/body-age';
import { BodyFat } from 'pages/body-fat';
import { BodyHeight } from 'pages/body-height';
import { BodyWeight } from 'pages/body-weight';
import { BodyIdealWeight } from 'pages/body-ideal-weight';
import { BodyHealthSummary } from 'pages/body-health-summary';
import { FitnessLevel } from 'pages/fitness-level';
import { Workouts } from 'pages/workouts';
import { WorkoutsTip } from 'pages/workouts-tip';
import { PhysicalLimitations } from 'pages/physical-limitations';
import { PhysicalLimitationsTip } from 'pages/physical-limitations-tip';
import { PushUps } from 'pages/push-ups';
import { PullUps } from 'pages/pull-ups';
import { SleepHours } from 'pages/sleep-hours';
import { SleepHoursTip } from 'pages/sleep-hours-tip';
import { StressLevel } from 'pages/stress-level';
import { StressLevelTip } from 'pages/stress-level-tip';
import { AtIdealShape } from 'pages/at-ideal-shape';
import { PastBlockers } from 'pages/past-blockers';
import { PastBlockersTip } from 'pages/past-blockers-tip';
import { FridgeFood } from 'pages/fridge-food';
import { GlassesWater } from 'pages/glasses-water';
import { GlassesWaterTip } from 'pages/glasses-water-tip';
import { ConsumedItems } from 'pages/consumed-items';
import { EatTimes } from 'pages/eat-times';
import { SingleMeal } from 'pages/single-meal';
import { SpecificDiet } from 'pages/specific-diet';
import { SpecificDietTip } from 'pages/specific-diet-tip';
import { IntermittentFastingTip } from 'pages/intermittent-fasting-tip';
import { IntermittentFasting } from 'pages/intermittent-fasting';
import { WorkoutTimes } from 'pages/workout-times';
import { WorkoutLong } from 'pages/workout-long';
import { WorkoutPlan } from 'pages/workout-plan';
import { WorkoutPlace } from 'pages/workout-place';
import { WorkoutEquipment } from 'pages/workout-equipment';
import { CustomPlan } from 'pages/custom-plan';

import { Email } from 'pages/email';
import { NewsOffer } from 'pages/news-offer';
import { TrialPay } from 'pages/trial-pay';

import { Congrats } from 'pages/congrats';
import { Welcome } from 'pages/welcome';
import { BodyPart } from 'pages/body-part';
import { DesiredLook } from 'pages/desired-look';
import { Motivation } from 'pages/motivation';
import { SpecificEvent } from 'pages/specific-event';
import { NotFound } from 'pages/not-found';
import { PlanLoader } from 'pages/plan-loader';
import { UpgradePlan } from 'pages/upgrade-plan';

import { Graphs } from 'pages/graphs';
import { WorkoutMotivationRating } from 'pages/workout-motivation-rating';
import { SupportMotivationRating } from 'pages/support-motivation-rating';
import { OldRoutineRating } from 'pages/old-routine-rating';
import { StickWorkoutRoutineRating } from 'pages/stick-workout-routine-rating';
import { ConsistentExerciseRating } from 'pages/consistent-exercise-rating';
import { PersonalizedProgram } from 'pages/personalized-program';
import { YouAreAble } from 'pages/you-are-able';
import { BodyAgeFirstScreen } from 'pages/body-age-first-screen';
import { MainPage } from 'pages/main-page';

const ReactLazyPreload = (importStatement: () => Promise<any>) => {
        const Component = lazy(importStatement);

        // @ts-ignore
        Component.preload = importStatement;

        return Component;
};

const Paywall = ReactLazyPreload(() => import('pages/paywall'));

export const RouterConfig = () => {
    return (
        <Routes>
            {/* List all public routes here */}
            <Route path={RoutePath.MainPage} element={<MainPage />} />
            <Route path={RoutePath.BodyAgeFirstScreen} element={<BodyAgeFirstScreen />} />
            <Route path={RoutePath.DesiredLook} element={<DesiredLook />} />
            <Route path={RoutePath.Motivation} element={<Motivation />} />
            <Route path={RoutePath.SpecificEvent} element={<SpecificEvent />} />
            <Route path={RoutePath.BodyAge} element={<BodyAge />} />
            <Route path={RoutePath.BodyFat} element={<BodyFat />} />
            <Route path={RoutePath.BodyPart} element={<BodyPart />} />
            <Route path={RoutePath.BodyHeight} element={<BodyHeight />} />
            <Route path={RoutePath.BodyWeight} element={<BodyWeight />} />
            <Route path={RoutePath.BodyIdealWeight} element={<BodyIdealWeight />} />
            <Route path={RoutePath.BodyHealthSummary} element={<BodyHealthSummary />} />
            <Route path={RoutePath.FitnessLevel} element={<FitnessLevel />} />
            <Route path={RoutePath.Workouts} element={<Workouts />} />
            <Route path={RoutePath.WorkoutsTip} element={<WorkoutsTip />} />
            <Route path={RoutePath.PhysicalLimitations} element={<PhysicalLimitations />} />
            <Route path={RoutePath.PhysicalLimitationsTip} element={<PhysicalLimitationsTip />} />
            <Route path={RoutePath.PushUps} element={<PushUps />} />
            <Route path={RoutePath.PullUps} element={<PullUps />} />
            <Route path={RoutePath.SleepHours} element={<SleepHours />} />
            <Route path={RoutePath.SleepHoursTip} element={<SleepHoursTip />} />
            <Route path={RoutePath.StressLevel} element={<StressLevel />} />
            <Route path={RoutePath.StressLevelTip} element={<StressLevelTip />} />
            <Route path={RoutePath.AtIdealShape} element={<AtIdealShape />} />
            <Route path={RoutePath.PastBlockers} element={<PastBlockers />} />
            <Route path={RoutePath.PastBlockersTip} element={<PastBlockersTip />} />
            <Route path={RoutePath.FridgeFood} element={<FridgeFood />} />
            <Route path={RoutePath.GlassesWater} element={<GlassesWater />} />
            <Route path={RoutePath.GlassesWaterTip} element={<GlassesWaterTip />} />
            <Route path={RoutePath.ConsumedItems} element={<ConsumedItems />} />
            <Route path={RoutePath.EatTimes} element={<EatTimes />} />
            <Route path={RoutePath.SingleMeal} element={<SingleMeal />} />
            <Route path={RoutePath.SpecificDiet} element={<SpecificDiet />} />
            <Route path={RoutePath.SpecificDietTip} element={<SpecificDietTip />} />
            <Route path={RoutePath.IntermittentFasting} element={<IntermittentFasting />} />
            <Route path={RoutePath.IntermittentFastingTip} element={<IntermittentFastingTip />} />
            <Route path={RoutePath.WorkoutTimes} element={<WorkoutTimes />} />
            <Route path={RoutePath.WorkoutLong} element={<WorkoutLong />} />
            <Route path={RoutePath.WorkoutPlan} element={<WorkoutPlan />} />
            <Route path={RoutePath.WorkoutPlace} element={<WorkoutPlace />} />
            <Route path={RoutePath.WorkoutEquipment} element={<WorkoutEquipment />} />
            <Route path={RoutePath.CustomPlan} element={<CustomPlan />} />
            <Route path={RoutePath.Email} element={<Email />} />
            <Route path={RoutePath.NewsOffer} element={<NewsOffer />} />
            <Route path={RoutePath.PlanLoader} element={<PlanLoader />} />
            <Route path={RoutePath.TrialPay} element={<TrialPay />} />
            <Route path={RoutePath.Paywall} element={<Paywall />} />
            <Route path={RoutePath.UpgradePlan} element={<UpgradePlan />} />
            <Route path={RoutePath.Congrats} element={<Congrats />} />
            <Route path={RoutePath.Welcome} element={<Welcome />} />

            <Route path={RoutePath.Graphs} element={<Graphs />} />
            <Route path={RoutePath.WorkoutMotivationRating} element={<WorkoutMotivationRating />} />
            <Route path={RoutePath.SupportMotivationRating} element={<SupportMotivationRating />} />
            <Route path={RoutePath.OldRoutineRating} element={<OldRoutineRating />} />
            <Route path={RoutePath.StickWorkoutRoutineRating} element={<StickWorkoutRoutineRating />} />
            <Route path={RoutePath.ConsistentExerciseRating} element={<ConsistentExerciseRating />} />

            <Route path={RoutePath.PersonalizedProgram} element={<PersonalizedProgram />} />
            <Route path={RoutePath.YouAreAble} element={<YouAreAble />} />

            {/* List a generic 404-Not Found route here */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
