import { PrimerProvider } from 'api/primer.api';

import { SUBSCRIPTION_ID, CUSTOMER_ID, BILLING_PLATFORM } from 'constants/payment';

import { PLANS_FOR_UPGRADE_TYPE } from './index';

export const UpgradeCustomerSubscription = (upgradePlan: PLANS_FOR_UPGRADE_TYPE) => {
    const subscriptionId = localStorage.getItem(SUBSCRIPTION_ID) as string || '';
    const customerId = localStorage.getItem(CUSTOMER_ID) as string || '';

    // Buying upgrade plan trial is free
    const trialAmount = 0;
    const trialPeriodDays = 0;
    const paymentType = BILLING_PLATFORM.Primer;

    const plan = Object.assign({}, { ...upgradePlan }, { currency: upgradePlan.currencyCode, price: upgradePlan.amount });

    return PrimerProvider.upgradeSubscription({ customerId, subscriptionId, plan, trialAmount, trialPeriodDays, paymentType });
};
