import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export const CortisolTestosterone = () => {
    const { t } = useTranslation();

    const { block, title, subtitle, descriptions } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const pageImages = useBackgroundImageService([
        'svg/graphs/cortisol-testosterone/graph-desktop.svg',
        'svg/graphs/cortisol-testosterone/graph-mobile.svg',
    ]);

    return (
        <div className={block}>
            <Typography variant={TypographyVariants.h1} className={title}>
                {t('graphs-title-2')}
            </Typography>

            {isDesktop && <img src={pageImages[0]} alt="Graph" />}
            {!isDesktop && <img src={pageImages[1]} alt="Graph" />}

            <Typography variant={TypographyVariants.h1} className={subtitle}>
                {t('graphs-subtitle-2')}
            </Typography>

            <div className={descriptions}>
                <Typography variant={TypographyVariants.h2}>
                    {t('graphs-description-2')}
                </Typography>

                <Typography variant={TypographyVariants.h2}>
                    {t('graphs-description-3')}
                </Typography>

                <Typography variant={TypographyVariants.h2}>
                    {t('graphs-description-4')}
                </Typography>
            </div>
        </div>
    )
};
