import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useEnchargeSearch } from 'hooks/use-encharge-search.hook';

import { Footer } from 'containers/footer';

import { ExperimentsKeys } from 'constants/experiments';

import { ThinProgressBar } from 'components/progress-bar-thin';

import { LogoHeader } from '../header/logo';
import { EmailHeader } from '../header/email';

import { setGtagClientId } from 'helpers/gtag-client-id';

import { useStyles } from './styles';

export const Content = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { routePath, UTMSearch, isRequiredKeysExists } = useEnchargeSearch();

    const { pageConfiguration } = useContext(BaseContext);

    const page = pageConfiguration
        .setNavigate(navigate)
        .setConfigurationByLocation(location)
        .getPageConfiguration();

    const mg_body_age_first_screen = localStorage.getItem(ExperimentsKeys.mg_body_age_first_screen);

    const backButtonNotAvailableLocations = [
        RoutePath.BodyAgeFirstScreen,
        mg_body_age_first_screen !== '1' ? RoutePath.DesiredLook : '',
        RoutePath.TrialPay,
        RoutePath.Congrats,
        RoutePath.Welcome,
    ];

    const [backButtonAvailable] = useState(!backButtonNotAvailableLocations.includes(location.pathname));

    useEffect(() => {
        pageConfiguration.handleOnPageEnter();
    }, [page]);

    useEffect(() => {
        if (routePath) {
            navigate(`..${routePath}${UTMSearch}`);
        }

        // @description: GA set a new client_id after redirect
        setTimeout(() => {
            setGtagClientId();
        }, 100);

    }, [routePath]);

    const { mainContent } = useStyles({
        scrollableContent: page.scrollableContent,
    });

    const footerVisible = [RoutePath.Paywall].includes(location.pathname);

    if (routePath !== null && routePath !== location.pathname) {
        return null;
    }

    if (page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys)) {

        // @description: GA set a new client_id after redirect
        setTimeout(() => {
            setGtagClientId();
        }, 100);
    }

    return (
        <>
            {(page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys))
                ?
                <Navigate to={`${RoutePath.MainPage}${UTMSearch}`} />
                :
                <>
                    {page.header.email ? <EmailHeader backButtonAvailable={backButtonAvailable} /> : null}
                    {page.header.logo ? <LogoHeader backButtonAvailable={backButtonAvailable} /> : null}
                    {
                        <>
                            {page.header.progress && <ThinProgressBar progress={page.header.progress} />}
                            {page.customWrapper ?
                                children
                                :
                                <div className={mainContent}>
                                    {children}
                                </div>
                            }
                        </>
                    }
                    {footerVisible && <Footer />}
                </>
            }
        </>
    );
};
