// node modules
import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ choiceButton }: ThemeProps) => ({
    choiceButton: {
        ...choiceButton.choiceButton,
        display: 'flex',
        alignItems: 'center',
        '&:active': {
            ...choiceButton.choiceButtonHovered,
        }
    },
    choiceButtonSelected: {
        ...choiceButton.choiceButtonSelected,
    },
    textAlignCenter: {
        justifyContent: 'center',

    },
    imageClass: {
        width: '76px',
        height: '88px',
    },
    oneRowBtns: {
        flexBasis: '90%',
        minWidth: 'unset',
        maxWidth: 'unset',

        '& span': {
            fontSize: '16px'
        }
    },
    choiceButtonWithImage: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        padding: '8px 16px'
    },
    '@media (hover: hover)': {
        choiceButton: {
            "&:hover": {
                ...choiceButton.choiceButtonHovered,
            },
        },
        choiceButtonSelected: {
            "&:hover": {
                ...choiceButton.choiceButtonSelected,
            },
        }
    }
}));
