import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    animationPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        height: '100%',
        width: '100vw',
        padding: '16px',
    },
    textWrapper: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    animatedText: {
        fontWeight: 600,
        opacity: 0,
        fontSize: '34px',
        lineHeight: '40px',
        letterSpacing: '0.0041em',
        padding: '4px',

            '&$first': {
                animation: '$animationFirst 6s 1 0s',
            },
            '&$second': {
                animation: '$animationSecond 6s 1 1.5s',
            },
            '&$third': {
                color: '#1375D6',
                animation: '$animationThird 3s 1 3s',
            },
    },
    dot: {
        color: '#FFFFFF'
    },
    '@keyframes animationFirst': {
        '0%': {
            opacity: 0,
        },
        '25%': {
            opacity: 1,
        },
        '75%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },

    },
    '@keyframes animationSecond': {
        '0%': {
            opacity: 0,
        },
        '25%': {
            opacity: 1,
        },
        '50%': {
            opacity: 1,
        },
        '75%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },

    },
    '@keyframes animationThird': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },

    },
    first: {},
    second: {},
    third: {},
    '@media screen and (max-width: 280px)': { // Galaxy Fold resolution
        animatedText: {
            fontSize: '2rem'
        }
    },
}));
