import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    block: {
        '& h2': {
            textAlign: 'inherit',
        },
        '& img': {
            width: '100%',
            marginBottom: '30px',
            minHeight: '218px',
        }
    }
});
