import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    questionTitle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 0 32px'
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    }
});
