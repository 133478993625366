import { palette } from "./palette";

export type TypographyType = {
    fontFamily: string,
    fontWeight: number,
    fontSize: string,
    fontStyle?: string,
    lineHeight: string,
    textAlign: string,
    color: string,
    margin?: string,
    letterSpacing?: string,
    transform?: string,
    pointerEvents?: string,
    top?: string,
    left?: string,
    position?: string,
    transition?: string,
    marginTop?: string,
    maxWidth?: string,
    whiteSpace?: string,
}

const h1: TypographyType & { [key: string]: {} } = {
    fontFamily: 'SF Pro Text',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: palette.white,
    margin: '32px 0',

    '@media only screen and (min-width: 768px)' : {
        fontSize: '22px',
        lineHeight: '30px',
        margin: '80px 0 40px'
    }
};

const h2: TypographyType & { [key: string]: {} } = {
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    color: palette.light_grey,
    margin: '16px 0 32px',

    '@media only screen and (min-width: 768px)' : {
        fontSize: '20px',
        lineHeight: '28px',
        margin: '16px 0 40px'
    }
};

const h3: TypographyType = {
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: palette.grey,
    margin: '0 auto',
};

const h4: TypographyType = h3;

const button: Partial<TypographyType> & { [key: string]: {} } = {
    fontFamily: 'SF Pro Text',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 600,

    '@media only screen and (min-width: 768px)' : {
        lineHeight: '24px',
    }
};

const choiceButton: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.0024em',
};

const multiChoiceButton: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.0024em',
    textAlign: 'left',
};

const inputStartAdornment: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '26px',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.0024em',
    color: palette.white,
    margin: '0 0 0 0.75rem',
};

const inputEndAdornment: Partial<TypographyType> = {
    ...inputStartAdornment,
    fontSize: '16px',
    lineHeight: '24px'
};

const inputLabel: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '16px',
    margin: '0px',
    top: '5px',
    color: palette.white,
    position: 'absolute',
    transform: 'translateY(calc(0.75rem - 1px))',
    transition: 'all 0.2s ease 0s',
    lineHeight: '24px',
    pointerEvents: 'none',
};

const inputLabelActive: Partial<TypographyType> = {
    ...inputLabel,
    top: '8px',
    fontSize: '.75rem',
    transform: 'translateY(0px)',
    lineHeight: '1rem',
};

const inputLabelError: Partial<TypographyType> = {
    ...inputLabelActive,
    color: palette.error,
    lineHeight: '16px'
};

const validatedInputError: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.error,
    margin: '4px 0 0 16px',
};

const tipPage: Partial<TypographyType> = {
    ...h1,
    fontWeight: 400,
    textAlign: 'left',
    margin: 'auto',
    whiteSpace: 'pre-line',
};

const ratingButton: Partial<TypographyType> = {
    ...multiChoiceButton,
    textAlign: 'center',
};

const ratingGradationInfo: Partial<TypographyType> = {
    ...h3,
    margin: 'unset',
};

export const TypographyStyles = {
    h1,
    h2,
    h3,
    h4,
    button,
    choiceButton,
    multiChoiceButton,
    inputStartAdornment,
    inputEndAdornment,
    inputLabel,
    inputLabelActive,
    inputLabelError,
    validatedInputError,
    tipPage,
    ratingButton,
    ratingGradationInfo,
};
