import React from 'react';

import { Button } from '../button';

import { useStyles } from './styles';

export const NextButton = ({children, typographyText = 'Next', ...props}: any) => {

    const { nextButton } = useStyles();

    return (
        <Button className={nextButton} typographyText={typographyText} {...props}>{children}</Button>
    );
}
