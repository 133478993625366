import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

const convertDataForIntakeForm = (data: { unit: string, height: string }) => {
    return `Height: ${data.height} ${data.unit === UNIT_SYSTEM.metric ? 'cm': 'ft/in'}`
};

export const BodyHeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const [pageError, setPageError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});


    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;
    const countryCode = localStorage.getItem(UserGeolocationKeys.countryCode) as string;

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: countryCode === 'US' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
        });

    const { pageValue: bodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const { pageValue: bodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            height:
                bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
                    ? `${bodyHeightFt}.${bodyHeightInches}`
                    : bodyHeightCm
        };

        saveAnswers({
            key: 'body-height',
            itemQuestion: { question: t('body-height-title'), value: convertDataForIntakeForm(data) },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
            ? pageError || !bodyHeightFt
            : pageError || !bodyHeightCm;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        bodyHeightFt,
        bodyHeightCm,
        pageError
    ]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('body-height-title')}</Typography>

            <BodyHeightWeightInputs heightInput idealWeightInput={false} setInputError={setError} pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
