import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const WorkoutPlan = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.WorkoutPlan];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'cardio',
            value: t('workout-plan-option-cardio')
        },
        {
            key: 'strength',
            value: t('workout-plan-option-strength')
        },
        {
            key: 'intensity',
            value: t('workout-plan-option-intensity')
        },
        {
            key: 'stretching',
            value: t('workout-plan-option-stretching')
        },
        {
            key: 'other',
            value: t('workout-plan-option-other'),
            userAnswer, setUserAnswer
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-plan-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete({
            value: pageValue,
            userAnswer: pageValue.includes('other') ? userAnswer.trim() : ''
        });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('workout-plan-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('workout-plan-subtitle')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
