import React from 'react';
import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from '../typography';
import { useStyles } from './styles';

interface Props {
    rating: RatingType;
    handleClick: (rating: RatingType) => void;
    selected?: boolean,
    buttonClass?: string,
    buttonTextClass?: string,
    isEmoji?: boolean,
}

export type RatingType = { key: string, value: string, imgSrc?: string };

export const RatingButton = (props: Props) => {
    const { ratingButton, ratingButtonSelected, ratingButtonEmoji } = useStyles();
    const { rating, handleClick } = props;

    let buttonClassName = `${ratingButton} ${props?.buttonClass} `;
    let buttonTextClassName = `${props?.buttonTextClass} `;

    if (props?.selected) {
        buttonClassName += ratingButtonSelected;
    }

    if (props?.isEmoji) {
        buttonClassName += ` ${ratingButtonEmoji}`;
    }

    return (
        <button onClick={()=> handleClick(rating)} className={buttonClassName}>
            {!props?.isEmoji &&
                <Typography variant={TypographyVariants.ratingButton} className={buttonTextClassName}>
                    {rating.value}
                </Typography>
            }
            {props?.isEmoji && <img src={props.rating.imgSrc} alt="" />}
        </button>
    );
};
