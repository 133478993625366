import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    muscleLevelBlock: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        margin: '10px 0',
        '& div': {
            width: '20px',
            height: '4px',
            borderRadius: '4px',
            backgroundColor: '#5A5A5A'
        }
    },
    '@media only screen and (min-width: 768px)' : {
        muscleLevelBlock: {
            margin: '6px 0',
        }
    }
});
