import { createUseStyles } from 'react-jss';
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    floatedButtonWrapper: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
        left: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
    },
    gradient: {
        maxWidth: 'inherit',
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${palette.dark_black} 21.6%)`,
    },
    padding: {
        padding: '0 16px 48px',
    },
});
