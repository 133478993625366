export const PAYMENT_METHODS = {
    primerIO: 'PrimerIO',
    creditCard: 'card',
    applePay: 'Apple Pay',
    payPal: 'PayPal', // paypal_express_checkout
    googlePay: 'Google Pay',
};

export const SUBSCRIPTION_ID = 'subscriptionid';

export const CUSTOMER_ID = 'customerid';

export enum BILLING_PLATFORM {
    Primer = 'Primer.io',
}
