import { createUseStyles } from 'react-jss';

import { ThemeProps } from '../../../themes';

export const useStyles = createUseStyles(({ header, backArrowButton }: ThemeProps) => ({
    header: {
        zIndex: 2,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: header.header.height,
        left: '0px',
        background: header.header.background,
        position: header.header.position,
    },
    backButtonWrapper: {
        ...backArrowButton.backButtonWrapper,
    },
    backButtonIconInnerWrapper: {
        ...backArrowButton.backButtonIconInnerWrapper,
    },
    backButton: {
        ...backArrowButton.backButton,
    },
    headerContent: {
        padding: '0 16px 0 2px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: '36px',
        background: '#373739',
        borderRadius: '20px',
    },
    headerAvatar: {
        fontFamily: header.headerAvatar.fontFamily,
        fontWeight: header.headerAvatar.fontWeight,
        fontStyle: header.headerAvatar.fontStyle,
        fontSize: header.headerAvatar.fontSize,
        lineHeight: header.headerAvatar.lineHeight,
        textAlign: 'center',
        letterSpacing: header.headerAvatar.letterSpacing,
        color: header.headerAvatar.color,
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        background: header.headerAvatar.background,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
    },
    headerEmail: {
        display: 'inline-block',
        width: 'auto',
        maxWidth: '60vw',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontStyle: header.headerEmail.fontStyle,
        fontWeight: header.headerEmail.fontWeight,
        letterSpacing: header.headerEmail.letterSpacing,
        color: header.headerEmail.color,

        '@media (min-width: 600px)': {
            maxWidth: '470px',
        },
    },
}));
