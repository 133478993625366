import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const ConsumedItems = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.ConsumedItems];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'sweet',
            value: t('consumed-items-option-sweet')
        },
        {
            key: 'alcohol',
            value: t('consumed-items-option-alcohol')
        },
        {
            key: 'salty',
            value: t('consumed-items-option-salty')
        },
        {
            key: 'fried',
            value: t('consumed-items-option-fried')
        },
        {
            key: 'sugary',
            value: t('consumed-items-option-sugary')
        },
        {
            key: 'none',
            value: t('consumed-items-option-none')
        },
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('consumed-items-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('consumed-items-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('consumed-items-subtitle')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
