import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    title: {
        margin: '24px 0 0',
        lineHeight: '32.5px',
        fontSize: '28px',
    },
    summaryBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '16px',
        alignItems: 'stretch',
    },
    summaryInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        flex: 'auto 1',
    },
    summaryInfoBlock: {
        padding: '12px 16px',
        background: '#161616',
        borderRadius: '8px',
        '& h3': {
            textAlign: 'left',
            color: '#A0A0A0',
            fontWeight: 500,
            lineHeight: '21.5px',
        },
        '& $fatInfo': {
            color: '#FFFFFF',
        }
    },
    fatInfo: {},
    bodyImageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '8px',
        background: '#161616',
        position: 'relative',
        minWidth: '128px',
        overflow: 'hidden',
        '& img': {
            height: '246px',
            position: 'absolute',
            top: '5%',
        }
    },
    bmiBlock: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    bmiBlockInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h3': {
            margin: 'unset',
            color: '#FFFFFF',
        }
    },
    slider: {
        bottom: '10px',
        width: '100%',
        height: '6px',
        backgroundImage: 'linear-gradient(270deg, #AE0D0D 0%, #DBDF0F 33.52%, #379029 62.01%, #2D84CA 90.22%)',
        backgroundRepeat: 'no-repeat',
        borderRadius: '100px',
    },
    popoverWrapper: {
        position: 'relative',
        width: 'fit-content',
    },
    popover: {
        background: '#333333',
        borderRadius: '8px',
        padding: '4px 8px',
        marginBottom: '15px',
        '&::after': {
            border: 'solid transparent',
            content: '" "',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(255, 255, 255, 0)',
            borderWidth: '5px',
            left: '50%',
            top: '100%',
            borderTopColor: '#333333',
            marginLeft: '-3px',
        },
        '&::before': {
            border: 'solid transparent',
            content: '" "',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(0, 0, 0, 0)',
            left: '50%',
            top: '100%',
            marginLeft: '-11px',
        }
    },
    dotSymbol: {
        position: 'absolute',
        borderRadius: '50%',
        width: '16px',
        height: '16px',
        backgroundColor: '#FFFFFF',
        top: '140%',
        left: '34%',
        border: '1px solid #000',
    },
    sliderFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h3': {
            fontSize: '12px',
            color: '#FFFFFF',
            margin: 'inherit',
        },
    },
    description: {
        padding: '16px',
        border: '2px solid #3A3A3A',
        borderRadius: '12px',
        '& h3': {
            color: '#D3D3D3',
            textAlign: 'inherit'
        }
    },
    '@media only screen and (min-width: 768px)' : {
        wrapper: {
            gap: '40px',
        },
        title: {
            margin: '80px 0 0',
            lineHeight: '30px',
            fontSize: '22px',
        },
        summaryBlock: {
            gap: '24px',
        },
        summaryInfoWrapper: {
            gap: '24px',
        },
        summaryInfoBlock: {
            '& h3': {
                fontSize: '16px',
                lineHeight: '18px',
                '&:first-of-type': {
                    marginBottom: '12px'
                }
            },
        },
        bodyImageWrapper: {
            minWidth: '200px',
            '& img': {
                height: '275px',
            }
        },
    }
});
