import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const WORKOUT_TIMES_ANSWERS: ChoiceType[] = [
    {
        key: '1-2-times',
        value: 'workout-times-option-1-2-times',
    },
    {
        key: '3-5-times',
        value: 'workout-times-option-3-5-times',
    },
    {
        key: '5-7-times',
        value: 'workout-times-option-5-7-times',
    },
];

export const WorkoutTimes = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.WorkoutTimes];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-times-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('workout-times-title')}
            </Typography>

            {WORKOUT_TIMES_ANSWERS.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={t(v.value)}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
