import React from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const SpecificDietTip = () => {
    const { t } = useTranslation();

    const userDiet = localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]) as string;

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/apple.svg'
            text={{
                description: 'specific-diet-tip-description',
                values: userDiet === 'other' ? t('specific-diet-tip-option-specific-diet') : userDiet
            }}
        />
    );
};
