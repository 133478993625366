import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    bar: {
        height: '48px !important',
        borderRadius: '8px',
        '& > div': {
            borderRadius: '8px'
        },
        '& p': {
            color: '#FFFFFF'
        }
    },
    animationWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px'
    },
    imageWrapper: {
        borderRadius: '16px',
        background: '#1A1A1A',
        padding: '0px 42px',
        width: '274px',
        minHeight: '40vh',
    },
    progressLabel: {
        fontSize: '16px',
        lineHeight: '26px',
        '& span': {
            color: '#E6E6E6'
        }
    },
    '@media (min-width: 768px)': {
        progressLabel: {
            margin: '40px 0',
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '28px',
        }
    }
});
