export const BODY_FAT_PERCENTAGE = [
    { min: 5, max: 9, label: '5-9%' },
    { min: 10, max: 14, label: '10-14%' },
    { min: 15, max: 19, label: '15-19%' },
    { min: 20, max: 24, label: '20-24%' },
    { min: 25, max: 29, label: '25-29%' },
    { min: 30, max: 34, label: '30-34%' },
    { min: 35, max: 39, label: '35-39%' },
    { min: 40, max: 100, label: '+40%' }
];
