import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const WORKOUT_PLACES_ANSWERS: ChoiceType[] = [
    {
        key: 'gym',
        value: 'workout-place-option-gym',
    },
    {
        key: 'home',
        value: 'workout-place-option-home',
    },
    {
        key: 'outdoor',
        value: 'workout-place-option-outdoor',
    }
];

export const WorkoutPlace = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const mg_personal_statement_screens = useFeatureIsOn(ExperimentsKeys.mg_personal_statement_screens as any);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_personal_statement_screens, mg_personal_statement_screens ? '1' : '0');
    }, [mg_personal_statement_screens]);

    const pageKey = LocalStorageKeys[RoutePath.WorkoutPlace];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-place-title'), value: t(answer.value) },
        });

        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('workout-place-title')}</Typography>

            {WORKOUT_PLACES_ANSWERS.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={t(v.value)}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
