import { RoutePath } from 'routes/route-path.constant';

export const LocalStorageKeys = {
    [RoutePath.DesiredLook]: 'desiredLook',
    [RoutePath.BodyPart]: 'bodyPart',
    [RoutePath.Motivation]: 'motivation',
    [RoutePath.SpecificEvent]: 'specificEvent',
    [RoutePath.BodyAge]: 'age',
    [RoutePath.BodyFat]: 'body-fat',
    [RoutePath.BodyIdealWeight]: 'weightGoal',
    [RoutePath.FitnessLevel]: 'fitness-level',
    [RoutePath.StressLevel]: 'stress-level',
    [RoutePath.Workouts]: 'workouts',
    [RoutePath.PhysicalLimitations]: 'physical-limitations',
    [RoutePath.PushUps]: 'push-ups',
    [RoutePath.PullUps]: 'pull-ups',
    [RoutePath.SleepHours]: 'sleep-hours',
    [RoutePath.AtIdealShape]: 'at-ideal-shape',
    [RoutePath.PastBlockers]: 'past-blockers',
    [RoutePath.FridgeFood]: 'fridge-food',
    [RoutePath.GlassesWater]: 'glasses-water',
    [RoutePath.ConsumedItems]: 'consumed-items',
    [RoutePath.EatTimes]: 'eat-times',
    [RoutePath.SingleMeal]: 'single-meal',
    [RoutePath.SpecificDiet]: 'specific-diet',
    [RoutePath.IntermittentFasting]: 'intermittent-fasting',
    [RoutePath.WorkoutTimes]: 'workout-times',
    [RoutePath.WorkoutLong]: 'workout-long',
    [RoutePath.WorkoutPlan]: 'workout-plan',
    [RoutePath.WorkoutPlace]: 'workout-place',
    [RoutePath.WorkoutEquipment]: 'workout-equipment',
    [RoutePath.TrialPay]: 'trial-pay',
    [RoutePath.Email]: 'email',
    [RoutePath.UpgradePlan]: 'newplan',
    [RoutePath.WorkoutMotivationRating]: 'workout-motivation',
    [RoutePath.SupportMotivationRating]: 'support-motivation',
    [RoutePath.OldRoutineRating]: 'old-routine',
    [RoutePath.StickWorkoutRoutineRating]: 'stick-workout',
    [RoutePath.ConsistentExerciseRating]: 'consistent-exercise',
};

export const MultipleLocalStorageKeys = {
    bodyHeightWeightKeys: {
        unitSystem: 'body-height-weight-unitSystem',
        heightCm: 'body-height-cm',
        heightFt: 'body-height-ft',
        heightInches: 'body-height-inches',
        weightLbs: 'body-weight-lbs',
        weightKg: 'body-weight-kg'
    },
};
