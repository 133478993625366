export type Palette = Record<string, string>;

export const palette = {
    primary: '#EB731A',
    primary_dark: '#41352D',
    black: '#1C1C1E',
    dark_black: '#010101',
    light_grey: '#E6E6E6',
    grey: '#D3D3D3',
    dark_grey: '#373739',
    white: '#FFFFFF',
    error: '#FF5357',
    disabled_grey: '#757575',
    disabled_black: '#1C1C1E'
}
