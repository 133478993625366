import * as React from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { MAX_CHARACTERS } from 'constants/text-area-input';
import { TypographyVariants } from 'constants/typography-variants';

import { isBoolean } from 'helpers';

import { Button } from 'components/button';
import { TextAreaInput } from 'components/text-area-input';

import { useStyles } from './styles';

export type ChoiceType = { key: string; value: string; userAnswer?: string; setUserAnswer?: any; image?: string; };
type ChoiceButtonType = React.ButtonHTMLAttributes<HTMLButtonElement> &
    {
        item?: ChoiceType;
        pageValue?: string;
        setPageValue?: (v: string) => void;
        selected: boolean;
        center?: boolean;
        typographyVariant?: string;
        typographyText?: string;
        children?: React.ReactNode;
        horizontallyAlignedButtons?: boolean; // For single choice question in popups
    };

export const ChoiceButton = ({ item, pageValue, selected, center, typographyVariant, children, horizontallyAlignedButtons, ...props }: ChoiceButtonType) => {
    const { choiceButton, choiceButtonSelected, textAlignCenter, imageClass, oneRowBtns, choiceButtonWithImage } = useStyles();

    const pageImages = useBackgroundImageService([item?.image as string]);

    let selectBtnClass = `${choiceButton}`;

    if (isBoolean(center) && center) {
        selectBtnClass += ` ${textAlignCenter}`;
    }

    if (selected) {
        selectBtnClass += ` ${choiceButtonSelected}`;
    }

    if (horizontallyAlignedButtons) {
        selectBtnClass += ` ${oneRowBtns}`;
    }

    if (pageImages[0]) {
        selectBtnClass += ` ${choiceButtonWithImage}`;
    }

    let tVariant = TypographyVariants.choiceButton;

    if (typographyVariant) {
        // @ts-ignore // Remove Typography for passing from up, because of typographyVariant.choiceButton -> typographyVariant.choiceButtonTypography
        tVariant = TypographyVariants[typographyVariant.replace('Typography', '')] ? TypographyVariants[typographyVariant.replace('Typography', '')] : tVariant;
    }

    const maxCharacters = MAX_CHARACTERS;
    const textAreaInputShown = pageValue?.includes('other') && item?.key === 'other';

    return (
        <>
            <Button className={selectBtnClass} typographyVariant={tVariant} {...props}>
                {children}
                {pageImages[0] && <img className={imageClass} src={pageImages[0]} alt={item?.key} />}
            </Button>
            {textAreaInputShown && <TextAreaInput onChange={item.setUserAnswer}
                                                  value={item.userAnswer as string}
                                                  maxCharacters={maxCharacters} />
            }
        </>
    );
};
