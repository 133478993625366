import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Heading,
    ISingleChoiceButtonOption,
    SingleChoiceButton, SingleChoicesButtonLabel,
    SingleChoicesButtonWrapper
} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { useStyles } from './styles';

export const BodyAgeFirstScreen = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { choiceButtonsWrapper, choiceButtonWrapper, selected, labelWrapper } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyAge];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const pageImages = useBackgroundImageService([
        'webp/body-age-first-screen/18-29.webp',
        'webp/body-age-first-screen/30-39.webp',
        'webp/body-age-first-screen/40-49.webp',
        'webp/body-age-first-screen/50.webp'
    ]);

    const options = [
        { value: 'twentys', label: 'body-age-first-screen-option-twenties', image: pageImages[0] },
        { value: 'thirtys', label: 'body-age-first-screen-option-thirties', image: pageImages[1] },
        { value: 'fortys', label: 'body-age-first-screen-option-forties', image: pageImages[2] },
        { value: 'fiftys', label: 'body-age-first-screen-option-fifties', image: pageImages[3] },
    ];

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-age-first-screen-title'), value: t(option.label) },
        });
        setPageValue(option.value);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    return (
        <>
            <Heading title={t('body-age-first-screen-title')} subtitle={t('body-age-first-screen-subtitle')} />

            <div className={choiceButtonsWrapper}>
                <SingleChoicesButtonWrapper>
                    {options.map((option) => (
                        <SingleChoiceButton
                            key={option.value}
                            option={option}
                            selected={option.value === pageValue}
                            onClick={() => handleClick(option)}
                            className={`${choiceButtonWrapper} ${option.value === pageValue ? selected : ''}`}>
                            <div className={labelWrapper}>
                                <SingleChoicesButtonLabel label={t(option.label)} />
                            </div>
                            <img src={option.image} alt='' />
                        </SingleChoiceButton>
                    ))}
                </SingleChoicesButtonWrapper>
            </div>
        </>
    );
};
