import { createUseStyles } from 'react-jss'
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    footer: {
        position: 'relative',
        width: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#202020',
        borderTop: `1px solid ${palette.disabled_grey}`
    },
    footerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 400,
        letterSpacing: '-0.2px',
        padding: '16px',
        color: palette.white,
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '8px',

        '& a': {
            color: palette.white,
            textUnderlineOffset: '3px'
        }
    },
    contentBlock: {
        width: '80%',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    companyInfo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    '@media only screen and (max-width: 280px)' : {
        footerContent: {
            fontSize: '10px'
        }
    },
    '@media only screen and (max-width: 393px)' : {
        contentBlock: {
            width: '90%'
        }
    },
    '@media only screen and (max-width: 320px)' : {
        contentBlock: {
            width: '100%'
        }
    },
    '@media only screen and (min-width: 768px)' : {
        footerContent: {
            maxWidth: '380px',
            marginRight: 'auto',
            marginLeft: 'auto',
            padding: '32px 0',
            fontSize: '14px',
            lineHeight: '21px'
        },
        contentBlock: {
            width: '100%'
        },
        companyInfo: {
            justifyContent: 'space-between',
            flexDirection: 'row',

            '& span:last-child::before': {
                content: '", "'
            }
        },
        links: {
            marginTop: '12px'
        }
    },
});
