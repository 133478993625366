import { DEFAULT_PAGES_CONFIGURATION } from 'services/default-pages-configuration.service';

import { RoutePath } from 'routes/route-path.constant';
import { ExperimentsKeys } from 'constants/experiments';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

export const ABPagesConfiguration = () => {
    let finalConfiguration = Object.assign({}, DEFAULT_PAGES_CONFIGURATION);

    const mgDeleteMotivationAndSpecificEventScreensVar = localStorage.getItem(ExperimentsKeys.mg_delete_motivation_and_specific_event_screens);
    const mg_add_graphs_screen = localStorage.getItem(ExperimentsKeys.mg_add_graphs_screen) || '0';
    const mg_personal_statement_screens = localStorage.getItem(ExperimentsKeys.mg_personal_statement_screens) || '0';
    const mg_finisher_screens = localStorage.getItem(ExperimentsKeys.mg_finisher_screens) || '0';
    const mg_body_age_first_screen = localStorage.getItem(ExperimentsKeys.mg_body_age_first_screen);

    if (['1', '2'].includes(mg_add_graphs_screen)) {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.PastBlockers]: {
                ...finalConfiguration[RoutePath.PastBlockers],
                route: {
                    next: (answer: string[]) => answer[0] === 'none' ? RoutePath.Graphs : RoutePath.PastBlockersTip
                },
            },
            [RoutePath.PastBlockersTip]: {
                ...finalConfiguration[RoutePath.PastBlockersTip],
                route: {
                    next: () => RoutePath.Graphs,
                },
            },
            [RoutePath.Graphs]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 48.5,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.FridgeFood
                },
                events: {
                    onLeave: () => mg_add_graphs_screen === '1' ? 'MenGraphMuscleMass' : 'MenGraphCortisolTestosterone',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
        });
    }

    if (mgDeleteMotivationAndSpecificEventScreensVar === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.BodyPart]: {
                ...finalConfiguration[RoutePath.BodyPart],
                route: {
                    next: () => RoutePath.SpecificEvent
                },
            }
        });
    }

    if (mgDeleteMotivationAndSpecificEventScreensVar === '2') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Motivation]: {
                ...finalConfiguration[RoutePath.Motivation],
                route: {
                    next: () => RoutePath.BodyAge
                },
            }
        });
    }

    if (mgDeleteMotivationAndSpecificEventScreensVar === '3') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.BodyPart]: {
                ...finalConfiguration[RoutePath.BodyPart],
                route: {
                    next: () => RoutePath.BodyAge
                },
            }
        });
    }

    if (mg_personal_statement_screens === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.WorkoutEquipment]: {
                ...finalConfiguration[RoutePath.WorkoutEquipment],
                route: {
                    next: () => RoutePath.WorkoutMotivationRating
                },
            },
            [RoutePath.WorkoutMotivationRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 73,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.SupportMotivationRating
                },
                events: {
                    onLeave: () => 'MenWorkoutMotivationRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.SupportMotivationRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 74.5,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.OldRoutineRating
                },
                events: {
                    onLeave: () => 'MenSupportMotivationRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.OldRoutineRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 76,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.StickWorkoutRoutineRating
                },
                events: {
                    onLeave: () => 'MenOldRoutineRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.StickWorkoutRoutineRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 77.5,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.ConsistentExerciseRating
                },
                events: {
                    onLeave: () => 'MenStickWorkoutRoutineRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.ConsistentExerciseRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 79,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PlanLoader
                },
                events: {
                    onLeave: () => 'MenConsistentExerciseRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
        });
    }

    if (mg_finisher_screens === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Email]: {
                ...finalConfiguration[RoutePath.Email],
                route: {
                    next: (data: any) => {
                        if (data?.isUserActive) {
                            return RoutePath.Welcome;
                        }

                        return data?.hideNewsOffer ? RoutePath.PersonalizedProgram : RoutePath.NewsOffer;
                    }
                },
            },
            [RoutePath.NewsOffer]: {
                ...finalConfiguration[RoutePath.NewsOffer],
                route: {
                    next: () => RoutePath.PersonalizedProgram,
                },
            },
            [RoutePath.PersonalizedProgram]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.YouAreAble,
                },
                events: {
                    onEnter: () => 'MenFinisherStarterViewed',
                    onLeave: () => 'MenFinisher4Viewed',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
                    LocalStorageKeys[RoutePath.Email],
                ]
            },
            [RoutePath.YouAreAble]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.TrialPay,
                },
                events: {
                    onLeave: () => 'MenFinisherCompletedViewed',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
                    LocalStorageKeys[RoutePath.Email],
                ]
            },
        });
    }

    if (mg_body_age_first_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.DesiredLook]: {
                ...finalConfiguration[RoutePath.DesiredLook],
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            },
            [RoutePath.BodyPart]: {
                ...finalConfiguration[RoutePath.BodyPart],
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            },
            [RoutePath.Motivation]: {
                ...finalConfiguration[RoutePath.Motivation],
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            },
            [RoutePath.SpecificEvent]: {
                ...finalConfiguration[RoutePath.SpecificEvent],
                route: {
                    next: () => RoutePath.BodyFat
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            }
        });
    }

    return finalConfiguration;
};
