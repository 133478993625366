import { useEffect } from "react";

import { UserGeolocationProvider } from 'api/geo-coding.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UserGeolocationKeys } from 'constants/user-geolocation';

const CUBA_KEY = 'CU';
const US_KEY = 'US';
const BS_KEY = 'BS';

export const useUserGeolocation = () => {
    const { pageValue: countryCode, setPageValue: setCountryCode } = useLocalStorage({ key: UserGeolocationKeys.countryCode, defaultValue: '' });
    const { pageValue: city, setPageValue: setCity } = useLocalStorage({ key: UserGeolocationKeys.city, defaultValue: '' });
    const { pageValue: region, setPageValue: setRegion } = useLocalStorage({ key: UserGeolocationKeys.region, defaultValue: '' });
    const { pageValue: continentCode, setPageValue: setContinent } = useLocalStorage({ key: UserGeolocationKeys.continentCode, defaultValue: '' });
    const { pageValue: isEUCountry, setPageValue: setIsEUCountry } = useLocalStorage({ key: UserGeolocationKeys.isEUCountry, defaultValue: '' });
    const { pageValue: timezone, setPageValue: setTimezone } = useLocalStorage({ key: UserGeolocationKeys.timezone, defaultValue: '' });

    useEffect(() => {
        UserGeolocationProvider.getUserData().then((res: {
            country: string | null,
            city: string | null,
            region: string | null,
            continent: string | null,
            isEUCountry?: string | null,
            timezone?: string
        }) => {
            const countryCode = res?.country;
            if (countryCode) {
                const code = countryCode === CUBA_KEY ? US_KEY : countryCode;
                setCountryCode(code);
            } else {
                // @description: Set default country code to BS
                setCountryCode(BS_KEY);
            }

            if (res?.continent) {
                setContinent(res?.continent);
            }

            if (res?.isEUCountry) {
                setIsEUCountry(res?.isEUCountry);
            }

            if (res?.timezone) {
                setTimezone(res?.timezone);
            }

            if (res?.city) {
                setCity(res?.city);
            }

            if (res?.region) {
                setRegion(res?.region);
            }
        });
    }, []);

    return {
        continentCode,
        countryCode,
        isEUCountry,
        timezone,
        city,
        region
    }
};
