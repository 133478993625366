import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    nextButton: {
        ...nextButton.nextButton,
        margin: '32px 0',

        '&:active': {
            ...nextButton.nextButtonFocus,
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
