import React from 'react';

import { TipPage } from 'components/tip-page';

export const PastBlockersTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/ladder-with-star.svg'
            text={{
                description: 'past-blockers-tip-description'
            }}
        />
    );
};
