import React from 'react';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const Button = ({buttonWrapperClassName, className, onClick, disabled, bottomMargin, typographyVariant, typographyText, children, ...props}: any) => {

    const { buttonWrapper, button, buttonDisabled, marginBottom } = useStyles();

    const btnClassName = `${button} ${className} ${disabled && buttonDisabled} ${bottomMargin && marginBottom}`;
    const btnWrapperClassName = `${buttonWrapper} ${buttonWrapperClassName}`;

    let tVariant = TypographyVariants.button;

    if (typographyVariant) {
        // @ts-ignore // Remove Typography for passing from up, because of typographyVariant.choiceButton -> typographyVariant.choiceButtonTypography
        tVariant = TypographyVariants[typographyVariant.replace('Typography', '')] ? TypographyVariants[typographyVariant.replace('Typography', '')] : tVariant;
    }

    const clickHandler = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
            <div className={btnWrapperClassName}>
                <button
                    onClick={clickHandler}
                    className={btnClassName}
                    disabled={disabled}
                    {...props}>
                        {children}
                        {typographyText
                            ? (
                                <Typography
                                    variant={tVariant}>
                                    {typographyText}
                                </Typography>
                            )
                            : null
                        }
                </button>
            </div>
        );
}
