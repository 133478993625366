export const CurrencyLabelsByCountries = {
    AU: '$',
    CA: '$',
    EU: '€',
    US: '$',
    GB: '£',
    IN: '₹'
};

export const CurrencyLabelsByCodes: any = {
    USD: CurrencyLabelsByCountries.US,
    EUR: CurrencyLabelsByCountries.EU,
};

