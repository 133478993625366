export const isBoolean = (val: any) => typeof val === 'boolean';

export const isString = (val: any) => typeof val === 'string';

export const isNumber = (val: any) => typeof val === 'number' && isFinite(val);

export const isEmpty = (val: any) => val.trim().length === 0;

export const debounce = (callback: (...args: any) => void, timeout = 300) => {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { callback.apply(this, args); }, timeout);
    };
};

/* eslint-disable */
export const uuidv4 = () => {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
};
/* eslint-enable */

export const getPositiveNumber = (number: number, minus: number): number => {
    const result = number - minus;
    return result < 0 ? getPositiveNumber(number, minus - 1) : result;
};
