import { UNIT_SYSTEM, getUnitSystem } from 'constants/body-height-weight';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { BODY_FAT_PERCENTAGE } from 'constants/body-fat';

import { convertToCm, convertToKgWithoutRounding } from 'helpers/unit-converter';

export const getBmi = (weight: number, height: number): number => {
    const unitSystem = getUnitSystem();

    const adjustedHeight = unitSystem === UNIT_SYSTEM.imperial ? height : height / 100;
    return weight / (adjustedHeight * adjustedHeight) * (unitSystem === UNIT_SYSTEM.imperial ? 703 : 1);
};

export const getUserBmi = (): number => {
    const unitSystem = getUnitSystem();
    const bodyHeightWeight = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const weight = unitSystem === UNIT_SYSTEM.imperial
        ? parseInt(localStorage.getItem(bodyHeightWeight.weightLbs) || '0')
        : parseInt(localStorage.getItem(bodyHeightWeight.weightKg) || '0');

    const height = unitSystem === UNIT_SYSTEM.imperial
        ? parseInt(localStorage.getItem(bodyHeightWeight.heightFt) || '0') * 12 + parseInt(localStorage.getItem(bodyHeightWeight.heightInches) || '0')
        : parseInt(localStorage.getItem(bodyHeightWeight.heightCm) || '0');

    return getBmi(weight, height);
};

export const getLBM = (metricWeight: number, bodyFatPercentage: number): number => {
    return metricWeight * (1 - (bodyFatPercentage / 100));
};

export const getFFMI = (metricWeight: number, metricHeight: number, bodyFatPercentage: number): number => {
    const lbm = getLBM(metricWeight, bodyFatPercentage);
    const ffmi = (lbm / Math.pow(metricHeight / 100, 2) + 6.1 * (1.8 - metricHeight / 100));

    console.log('metricWeight', metricWeight);
    console.log('metricHeight / 100', metricHeight / 100);
    console.log('bodyFatPercentage', bodyFatPercentage);
    console.log('lbm', lbm);
    console.log('lbm', ffmi);

    return (lbm / Math.pow(metricHeight / 100, 2) + 6.1 * (1.8 - metricHeight / 100));
};

export const getUserFFMI = (): number => {
    const unitSystem = getUnitSystem();
    const bodyHeightWeight = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const weight = unitSystem === UNIT_SYSTEM.imperial
        ? convertToKgWithoutRounding(parseInt(localStorage.getItem(bodyHeightWeight.weightLbs) || '0'))
        : parseInt(localStorage.getItem(bodyHeightWeight.weightKg) || '0');

    const height = unitSystem === UNIT_SYSTEM.imperial
        ? convertToCm(parseInt(localStorage.getItem(bodyHeightWeight.heightFt) || '0'), parseInt(localStorage.getItem(bodyHeightWeight.heightInches) || '0'))
        : parseInt(localStorage.getItem(bodyHeightWeight.heightCm) || '0');

    const bodyFat = parseInt(localStorage.getItem(LocalStorageKeys[RoutePath.BodyFat]) as string) || 1;

    const bodyFatPercentage = BODY_FAT_PERCENTAGE[bodyFat - 1];

    return getFFMI(weight, height, (bodyFatPercentage.min + bodyFatPercentage.max) / 2);
};
