import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { BODY_FAT_PERCENTAGE } from 'constants/body-fat';

import { getUserBmi, getUserFFMI } from 'services/body-plan-calculator.service';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MuscleLevel } from 'components/muscle-level';

import { useStyles } from './styles';

const getBMILevel = (userBMI: number): number => {
    if (userBMI < 18.5) {
        return 0;
    }

    if (userBMI >= 18.5 && userBMI <= 24.9) {
        return 1;
    }

    if (userBMI > 24.9 && userBMI <= 29.9) {
        return 2;
    }

    return 3;
};

export const BodyHealthSummary = () => {
    const { t } = useTranslation();

    const { wrapper, title, summaryBlock, summaryInfoWrapper, summaryInfoBlock, fatInfo, bodyImageWrapper,
        bmiBlock, bmiBlockInfo,  slider, popoverWrapper, popover, dotSymbol, sliderFooter, description } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyFat }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyFat],
        defaultValue: 1,
    });

    const images = [
        'webp/body-fat/5-9.webp', 'webp/body-fat/10-14.webp',
        'webp/body-fat/15-19.webp', 'webp/body-fat/20-24.webp',
        'webp/body-fat/25-29.webp', 'webp/body-fat/30-34.webp',
        'webp/body-fat/35-39.webp', 'webp/body-fat/40.webp',
    ];

    const pageImages = useBackgroundImageService([images[bodyFat - 1]]);

    const userBMI = Number(getUserBmi().toFixed(1));
    console.log('userBMI', userBMI);
    const BMILevel = getBMILevel(userBMI);
    const userFFMI = getUserFFMI();
    const userBodyFat = BODY_FAT_PERCENTAGE[bodyFat - 1];

    const bmiTitles = [
        t('body-health-summary-bmi-level-underweight'),
        t('body-health-summary-bmi-level-normal'),
        t('body-health-summary-bmi-level-overweight'),
        t('body-health-summary-bmi-level-obese')
    ];

    const sliderPopupLeft = ['3%', '35%', '64%', '91%'];

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete({ bmi: userBMI, ffmi: userFFMI });
    };

    const tipDescription = useMemo(() => {
        const maxBodyFatPercentage = 14;
        const bodyFatHeight = ((userBodyFat.min + userBodyFat.max) / 2) <= maxBodyFatPercentage ? 'low' : 'high';

        if (userBMI < 18.5) {
            return 'body-health-summary-tip-description-main-rule';
        }

        if (userFFMI < 20) {
            return `body-health-summary-tip-description-low-ffmi-${bodyFatHeight}-fat`;
        }

        if (userFFMI >= 20 && userFFMI < 25) {
            return `body-health-summary-tip-description-mid-ffmi-${bodyFatHeight}-fat`;
        }

        if (userFFMI >= 25 && userFFMI < 30) {
            return `body-health-summary-tip-description-high-ffmi-${bodyFatHeight}-fat`;
        }

        // userFFMI >= 30
        return `body-health-summary-tip-description-max-ffmi-${bodyFatHeight}-fat`;
    }, [userBMI, userBodyFat]);

    return (
        <div className={wrapper}>

            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey={'body-health-summary-title'} />
            </Typography>

            <div className={summaryBlock}>

                <div className={summaryInfoWrapper}>

                    <div className={summaryInfoBlock}>
                        <Typography variant={TypographyVariants.h3}>
                            {t('body-health-summary-bmi-label')}
                        </Typography>
                        <Typography variant={TypographyVariants.h3} className={fatInfo}>
                            {userBodyFat.label}
                        </Typography>
                    </div>

                    <div className={summaryInfoBlock}>
                        <MuscleLevel label={t('body-health-summary-muscle-label')} ffmi={userFFMI} />
                    </div>

                </div>

                <div className={bodyImageWrapper}>
                    {pageImages?.length && <img src={pageImages[0]} alt='Your body' />}
                </div>

            </div>

            <div className={bmiBlock}>
                <div className={bmiBlockInfo}>
                    <Typography variant={TypographyVariants.h3}>
                        {t('body-health-summary-bmi-slider-label')}
                    </Typography>
                    <Typography variant={TypographyVariants.h3}>
                        {`${userBMI} (${bmiTitles[BMILevel]})`}
                    </Typography>
                </div>
                <div>
                    <div className={popoverWrapper} style={{left: sliderPopupLeft[BMILevel]}}>
                        <div className={popover}>
                            <Typography variant={TypographyVariants.h3}>
                                {t('body-health-summary-bmi-slider-popover')}
                            </Typography>
                        </div>
                        <div>
                            <span className={dotSymbol}></span>
                        </div>
                    </div>
                    <div className={slider}></div>
                </div>

                <div className={sliderFooter}>
                    <Typography variant={TypographyVariants.h3}>{bmiTitles[0]}</Typography>
                    <Typography variant={TypographyVariants.h3}>{bmiTitles[bmiTitles.length - 1]}</Typography>
                </div>

            </div>

            <div className={description}>
                <Typography variant={TypographyVariants.h3}>
                    <Trans i18nKey={tipDescription} />
                </Typography>
            </div>

            <NextButton onClick={handleClick} typographyText={t('body-health-summary-next-button')}/>
        </div>
    );
};
