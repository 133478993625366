import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

import { useStyles } from './styles';

export enum DESIRED_LOOK { SLIM = 'slim', SHREDDED = 'shredded', BODYBUILDER = 'bodybuilder' }

export const DesiredLook = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { choiceButtonImages } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.DesiredLook];

    const { pageValue: desiredLook, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const answers: ChoiceType[] = [
        {
            key: DESIRED_LOOK.SLIM,
            value: t('desired-look-option-slim'),
            image: 'png/desired-look/slim.png'
        },
        {
            key: DESIRED_LOOK.SHREDDED,
            value: t('desired-look-option-shredded'),
            image: 'png/desired-look/shredded.png'
        },
        {
            key: DESIRED_LOOK.BODYBUILDER,
            value: t('desired-look-option-bodybuilder'),
            image: 'png/desired-look/bodybuilder.png'
        }
    ];

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('desired-look-title'), value: t(answer.value) },
        });
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const mg_delete_motivation_and_specific_event_screens = useFeatureValue(ExperimentsKeys.mg_delete_motivation_and_specific_event_screens, '0');

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_delete_motivation_and_specific_event_screens, mg_delete_motivation_and_specific_event_screens);
    }, [mg_delete_motivation_and_specific_event_screens]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey='desired-look-title' />
            </Typography>

            <div className={choiceButtonImages}>
                {answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        item={v}
                        selected={v.key === desiredLook}
                        typographyText={t(v.value)}
                        onClick={() => handleClick(v)}
                    />
                ))}
            </div>
        </>
    );
};
