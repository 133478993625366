import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const IntermittentFastingTip = () => {

    const description = ['yes'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.IntermittentFasting]) as string)
        ? 'intermittent-fasting-tip-yes-description'
        : 'intermittent-fasting-tip-no-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/star.svg'
            text={{ description }}
        />
    );
};
