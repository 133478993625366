import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { RoutePath } from 'routes/route-path.constant';

import { useUTMSearch } from 'hooks/use-encharge-search.hook';

import { ExperimentsKeys } from 'constants/experiments';

export const MainPage = () => {
    const navigate = useNavigate();

    const [isVariantLoaded, setIsVariantLoaded] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVariantLoaded(true);
        }, 800);

        return () => clearTimeout(timeoutId);
    }, []);

    const UTMSearch = useUTMSearch();

    const mg_body_age_first_screen = useFeatureIsOn(ExperimentsKeys.mg_body_age_first_screen);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_body_age_first_screen, mg_body_age_first_screen ? '1' : '0');
    }, [mg_body_age_first_screen]);

    useEffect(() => {
        if (isVariantLoaded) {

            if (mg_body_age_first_screen) {
                navigate(`../${RoutePath.BodyAgeFirstScreen}${UTMSearch}`);
            } else {
                navigate(`../${RoutePath.DesiredLook}${UTMSearch}`);
            }

        }
    }, [mg_body_age_first_screen, isVariantLoaded]);

    return null;
};
