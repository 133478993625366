import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    description: {
        padding: '16px',
        border: '2px solid #3A3A3A',
        borderRadius: '12px',
        margin: '32px 0 12px 0',
        '& h3': {
            color: '#D3D3D3',
            textAlign: 'inherit',
            lineHeight: '20px',
        }
    }
});
