import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

const convertDataForIntakeForm = (data: { unit: string, bodyIdealWeight: number }) => {
    return `Ideal weight: ${data.bodyIdealWeight} ${data.unit === UNIT_SYSTEM.metric ? 'kg': 'lb'}`
};

export const BodyIdealWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { nextButton } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyIdealWeight];

    const { pageValue: bodyIdealWeight } = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const unitSystem = getUnitSystem();

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        pageConfiguration.handleOnPageComplete({ bodyIdealWeight, unit: unitSystem });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-ideal-weight-title'), value: convertDataForIntakeForm({ unit: unitSystem as string, bodyIdealWeight }) },
        });
    };

    const [pageError, setPageError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = pageError || !bodyIdealWeight;

        setDisabledButtonState(btnState);
    }, [
        bodyIdealWeight,
        pageError
    ]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('body-ideal-weight-title')}</Typography>

            <BodyHeightWeightInputs heightInput={false} idealWeightInput setInputError={setError} pageError={pageError} />

            <NextButton className={nextButton} onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
