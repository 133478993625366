import { Palette } from '../index';
import { ButtonStyles } from './button';

export type RatingScaleType = {
    ratingScaleWrapper: {
        display: string,
        flexDirection: string,
        justifyContent: string,
    },
    ratingScale: {
        display: string,
        flexDirection: string,
        justifyContent: string,
        width: string,
    },
    ratingButton: {
        background: Palette | string,
        padding: string,
        width: string,
        height: string,
        border: string,
        color: Palette | string,
        borderRadius: string,
        cursor: string,
    },
    ratingButtonSelected: {
        background: Palette | string,
        color: Palette | string,
        border?: string,
    },
    ratingButtonActive: {
        boxShadow: string,
    },
    ratingButtonHovered?: {
        background?: string,
        color?: string,
    },
    ratingGradationInfoWrapper: {
        display: string,
        justifyContent: string,
        alignItems: string,
        height: string,
        width: string,
        margin: string,
    },
}

export const RatingScaleStyles: RatingScaleType = {
    ratingScaleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ratingScale: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    ratingButton: {
        background: '#373739',
        padding: '16px',
        width: '62px',
        height: '56px',
        border: 'none',
        color: '#FFFFFF',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    ratingButtonSelected: {
        background: '#41352D',
        color: '#FFFFFF',
        border: '3px solid #EB731A'
    },
    ratingButtonActive: {
        boxShadow: 'none',
    },
    ratingGradationInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '18px',
        width: '100%',
        margin: '8px 0',
    },
    ratingButtonHovered: ButtonStyles.buttonHover,
};
