export enum TypographyVariants {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    span = 'span',
    label = 'label', // map top span ?
    button = 'buttonTypography',
    choiceButton = 'choiceButtonTypography',
    multiChoiceButton = 'multiChoiceButtonTypography',
    inputLabel = 'inputLabel',
    inputStartAdornment = 'inputStartAdornment',
    inputEndAdornment = 'inputEndAdornment',
    validatedInputError = 'validatedInputError',
    tipPage = 'tipPage',
    tab = 'tabTypography',
    ratingButton = 'ratingButtonTypography',
    ratingGradationInfo = 'ratingGradationInfoTypography',
}
