import { createContext } from "react";

import { BackgroundLoadingImages } from 'services/background-images.service';

const webWorkerScript = `
  self.addEventListener('message', event => {
    const url = event.data;

    fetch(url.importedUrl, {
        method: 'GET',
        mode: 'no-cors',
        cache: 'default'
    }).then(response => response.blob()).then(blob => postMessage({url, blob})).catch((e) => {console.error(e)});
  })
`;

class PreloadImageService {

    images: { [key: string]: any } = {};

    private worker = new Worker(URL.createObjectURL(
        new Blob([webWorkerScript], { type: 'application/javascript' })
    ));

    constructor() {
        BackgroundLoadingImages().images.map((image: {key: string, pathUrl: string}) => this.postMessageForWorker(image));

        this.worker.onmessage = (event: Object) => {
            // @ts-ignore
            const image = event?.data;

            // @ts-ignore
            this.images[image.url.key] = URL.createObjectURL(image?.blob);
        };
    }

    private importImageByPath = (path: string): Promise<{ default: string }> => import(`../assets/images/${path}`);

    private postMessageForWorker({ key, pathUrl } : {key: string, pathUrl: string}) {
        // Need to support data URLs
        const importedUrl = pathUrl.startsWith('data:') ? pathUrl : window.location.origin + pathUrl;
        this.worker.postMessage({
            key,
            importedUrl: importedUrl,
        });
    }

    getImageByLocalPath = (path: string): Promise<string | undefined> => {
        if(!path) {
            return Promise.resolve(undefined);
        }

        if (this.images[path]) {
            return Promise.resolve(this.images[path]);
        }

        return this.importImageByPath(path).then(module => module.default);
    }
}

export const PreloadImageServiceInstance = new PreloadImageService();
export const PreloadImageServiceInstanceCTX = createContext(PreloadImageServiceInstance);
