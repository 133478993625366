import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    mainContent: {
        maxWidth: 560,
        margin: '0 auto',
        position: 'relative',
        padding: ({ scrollableContent }: any) => scrollableContent ? '0px 16px 20px' : '0px 16px',

        '@media only screen and (min-width: 768px)' : {
            padding: ({ scrollableContent }: any) => scrollableContent ? '0px 0px 20px' : '0px',
        }
    },
    // Range slider fix (hide popover overlapping)
    '@media (max-width: 560px)': {
        mainContent: {
            overflowX: 'clip',
        }
    }
});
