import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    'animationPageWrapper': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        position: 'absolute',
        height: '100%',
        width: '100vw',
        padding: '16px',

        '@media (max-width: 500px)': {
            'alignItems': 'flex-start',
        },
    },
    'textWrapper': {
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'center',
        'alignItems': 'center',
        'height': '100%',
        'padding': '0 16px',
        'maxWidth': '560px',

        '& em': {
            'fontStyle': 'normal',
            'color': '#1375D6',
        },
    },
    'animatedText': {
        'opacity': 0,
        'fontWeight': 600,
        'fontSize': '34px',
        'lineHeight': '40px',
        'letterSpacing': '0.0041em',
        'textAlign': 'left',

        '&$first': {
            animation: `$animationFirst 2.5s 1 0s`,
        },
        '&$second': {
            animation: `$animationSecond 4s 1 0s`,
        },
        '&$third': {
            animation: `$animationThird 3s 1 0s`,
        }
    },
    '@keyframes animationFirst': {
        '0%': {
            opacity: 1,
        },
        '20%': {
            opacity: 1,
        },
        '70%': {
            opacity: 1,
        },
        '80%': {
            opacity: 0.5,
        },
        '90%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes animationSecond': {
        '0%': {
            opacity: 0,
        },
        '20%': {
            opacity: 1,
        },
        '70%': {
            opacity: 1,
        },
        '80%': {
            opacity: 0.5,
        },
        '90%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes animationThird': {
        '0%': {
            opacity: 0,
        },
        '20%': {
            opacity: 1,
        },
        '50%': {
            opacity: 1,
        },
        '70%': {
            opacity: 1,
        },
        '80%': {
            opacity: 0.5,
        },
        '90%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes animation': {
        '100%': {
            opacity: 1,
        },
    },
    'first': {},
    'second': {},
    'third': {},
}));
