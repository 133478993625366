import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo2 from '../../../assets/images/svg/logo.svg';

import { isBoolean } from 'helpers';
import { BackArrowButton } from 'components/back-arrow-button';

import { useStyles } from './styles';

export const LogoHeader = React.memo(({ classNames, backButtonAvailable }: any) => {

    const {
        header,
        backButton,
        backButtonWrapper,
        backButtonIconInnerWrapper,
    } = useStyles();

    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    // window.history.length > 0

    return (
        <header className={`${header} ${classNames?.LogoHeaderHeader}`}>

            {isBackButtonAvailable && <BackArrowButton backButtonWrapper={backButtonWrapper}
                                                       backButtonIconInnerWrapper={backButtonIconInnerWrapper}
                                                       backButton={backButton} handleBack={handleBack} />
            }

            <img src={logo2} alt='' />

            {isBackButtonAvailable && <div className={backButtonWrapper}></div>}
        </header>
    );
});
