import { Palette } from '../index';
import { palette } from './palette';

export type ChoiceButtonType = {
    choiceButton: {
        border: string,
        width: string,
        minWidth: string,
        maxWidth: string,
        minHeight: string,
        height: string,
        margin: string,
        padding: string,
    }
    choiceButtonSelected: {
        background: Palette | string
        color: Palette | string,
        border: string,
    },
    choiceButtonActive?: {
        boxShadow: string,
    },
    choiceButtonHovered?: {
        background: string,
        color?: string,
    }
}

export const ChoiceButtonStyles: ChoiceButtonType = {
    choiceButton: {
        border: `3px solid ${palette.dark_black}`,
        minWidth: '250px',
        width: '100%',
        maxWidth: '560px',
        minHeight: '59px', // Plus 3px border
        height: 'auto',
        margin: '0 0 9px',
        padding: '16px',
    },
    choiceButtonHovered: {
        background: palette.primary_dark,
        color: palette.white,
    },
    choiceButtonSelected: {
        background: palette.primary_dark,
        color: palette.white,
        border: `3px solid ${palette.primary}`,
    },
    choiceButtonActive: {
        boxShadow: 'none',
    }
};
