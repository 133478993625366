import React from 'react';

import dottedLoaderIcon from './dottedLoader.svg';

import { useStyles } from './styles';

interface Props {
    height?: number;
    width?: number;
    wrapperClass?: string;
}

export const DottedLoader = ({ height = 40, width = 40, wrapperClass }: Props) => {
    const style = {
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
    };
    const { dottedLoaderWrapper } = useStyles();

    return (
        <div className={`${dottedLoaderWrapper} ${wrapperClass && wrapperClass}`} style={style}>
            <img src={dottedLoaderIcon} alt="Loading"/>
        </div>
    );
};
