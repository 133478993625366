import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const PhysicalLimitations = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PhysicalLimitations];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        { key: 'none', value: t('physical-limitations-option-none') },
        { key: 'back', value: t('physical-limitations-option-back') },
        { key: 'knees', value: t('physical-limitations-option-knees') },
        { key: 'limited-mobility', value: t('physical-limitations-option-limited-mobility') },
        { key: 'injury', value: t('physical-limitations-option-injury') },
        { key: 'other', value: t('physical-limitations-option-other'), userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('physical-limitations-title'), value: selectOption },
        });

        const answer = {
            value: pageValue,
            userAnswer: pageValue.includes('other') ? userAnswer.toString().trim() : ''
        };

        pageConfiguration.handleOnPageComplete(answer);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('physical-limitations-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('physical-limitations-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
