import {isEmpty} from '../../helpers';

export type ValidationQueryType = {
    value: string | number,
    errorMessage?: string
}

export type ValidationTypeProps = {
    value: 'emptyString' | 'email' | 'number',
    errorMessage?: string,
    lessThen?: ValidationQueryType,
    moreThen?: ValidationQueryType,
    equal?: ValidationQueryType
}

const typeValidator = {
    emptyString: (value: string) => !isEmpty(value),
    email: (value: string) => value.length > 70 || !/^[\w%+-]+(\.[\w%+-]+)*@[\w%+-]+(\.[\w%+-]+)+$/.test(value)
}

export const validator = {
    maxLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length > options.value,
    minLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length < options.value,
    // @ts-ignore
    type: (value: string, { value: type }: ValidationTypeProps) => typeValidator[type](value)
}

export const numberValidator = {
    moreThen: (value: number, options: ValidationQueryType) => !(value > options.value),
    lessThen: (value: number, options: ValidationQueryType) => !(value < options.value),
    equal: (value: number, options: ValidationQueryType) => value !== options.value
}
