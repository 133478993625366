import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const AtIdealShape = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.AtIdealShape];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('at-ideal-shape-title'), value: t(answer.value) },
        });
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const choices: ChoiceType[] = [
        { key: 'one-to-six-month', value: t('at-ideal-shape-option-one-to-six-month') },
        { key: 'six-to-twelve-month', value: t('at-ideal-shape-option-six-to-twelve-month') },
        { key: 'one-to-two-year', value: t('at-ideal-shape-option-one-to-two-year') },
        { key: 'two-plus-year', value: t('at-ideal-shape-option-two-plus-year') },
        { key: 'right-now', value: t('at-ideal-shape-option-right-now') },
        { key: 'never-was', value: t('at-ideal-shape-option-never-was') },
    ];

    const mg_add_graphs_screen = useFeatureValue(ExperimentsKeys.mg_add_graphs_screen, '0');

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_add_graphs_screen, mg_add_graphs_screen as string);
    }, [mg_add_graphs_screen]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('at-ideal-shape-title')}
            </Typography>

            {choices.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
