import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    bodyImageWrapper: {
        margin: '32px 0',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '246px'
    },
    title: {},
    bodyImage: {
        height: '246px',
    },
    description: {
        padding: '16px',
        border: '2px solid #3A3A3A',
        borderRadius: '12px',
        margin: '32px 0 12px 0',
        '& h3': {
            color: '#D3D3D3',
            textAlign: 'inherit'
        }
    },
    '@media only screen and (min-width: 768px)' : {
        bodyImageWrapper: {
            margin: '80px 0 40px',
        },
        title: {
            margin: '40px 0',
        },
    }
});
