import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const GlassesWater = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.GlassesWater];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('glasses-water-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const answers: ChoiceType[] = [
        {
            key: 'coffee-tea',
            value: t('glasses-water-option-coffee-tea'),
        },
        {
            key: 'up-2-glasses',
            value: t('glasses-water-option-up-2-glasses'),
        },
        {
            key: 'up-6-glasses',
            value: t('glasses-water-option-up-6-glasses'),
        },
        {
            key: 'up-10-glasses',
            value: t('glasses-water-option-up-10-glasses'),
        },
        {
            key: 'more-10-glasses',
            value: t('glasses-water-option-more-10-glasses'),
        },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('glasses-water-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
