import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const SpecificEvent = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.SpecificEvent];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'vacation',
            value: t('specific-event-option-vacation')
        },
        {
            key: 'wedding',
            value: t('specific-event-option-wedding')
        },
        {
            key: 'sporting-event',
            value: t('specific-event-option-sporting-event')
        },
        {
            key: 'beach-trip',
            value: t('specific-event-option-beach-trip')
        },
        {
            key: 'reunion',
            value: t('specific-event-option-reunion')
        },
        {
            key: 'other',
            value: t('specific-event-option-other'),
            userAnswer,
            setUserAnswer
        },
        {
            key: 'none',
            value: t('specific-event-option-no')
        }
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('specific-event-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete({
            value: pageValue,
            userAnswer: pageValue.includes('other') ? userAnswer.trim() : ''
        });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey='specific-event-title' />
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    disabled={pageValue[0] === 'none' && v.key !== 'none'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
