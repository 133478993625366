import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

import { WORKOUTS_ANSWERS } from '../workouts';

export const WorkoutsTip = () => {

    const description = WORKOUTS_ANSWERS.NOT === localStorage.getItem(LocalStorageKeys[RoutePath.Workouts])
        ? 'workouts-tip-text-not-description'
        : 'workouts-tip-text-normal-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/mountain-with-flag.svg'
            text={{ description }}
        />
    );
};
