import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ multiChoiceButton }: ThemeProps) => ({
    checkboxContainer: {
        position: 'relative',
        width: '24px',
        cursor: 'pointer',
        marginRight: '16px',
        paddingRight: '16px',

        '& input': {
            /*  Hide the browser's default checkbox  */
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
            /* Show the checkmark when checked */
            '&:checked': {
                '& ~ $checkboxCheckmark': {
                    backgroundColor: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.backgroundColor,
                    '&:after': {
                        display: 'block',
                    },
                },
            },
        },

        '& $checkboxCheckmark': {
            '&:after': {
                left: '9px',
                top: '5px',
                width: '5px',
                height: '10px',
                border: `solid ${multiChoiceButton.multiChoiceButtonCheckboxCheckmark.color}`,
                borderWidth: '0 2px 2px 0',
                transform: 'rotate(45deg)',
            },
        },

        /* On mouse-over, add a grey background color */
        '&:hover': {
            '& input': {
                '& ~ $checkboxCheckmark': {
                    backgroundColor: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.backgroundColor,
                },
            },
        },
    },

    checkboxCheckmark: {
        position: 'absolute',
        top: '-12px',
        left: 0,
        height: '24px',
        width: '24px',
        border: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.border,
        borderRadius: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.borderRadius,
        borderColor: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.borderColor,
        backgroundColor: multiChoiceButton.multiChoiceButtonCheckboxCheckmark.backgroundColor,
        '&:after': {
            content: '""',
            position: 'absolute',
            display: 'none',
        },
    },
}));
