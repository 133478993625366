import React from 'react';

import { Typography } from '../typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

export interface MuscleLevelProps {
    label: string;
    ffmi: number;
}

export const MuscleLevel = ({ label, ffmi }: MuscleLevelProps) => {
    const { muscleLevelBlock } = useStyles();

    return (
        <>
            <Typography variant={TypographyVariants.h3}>
                {label}
            </Typography>
            <div className={muscleLevelBlock}>
                <div style={{backgroundColor: '#4682B4'}}></div>
                <div style={{backgroundColor: ffmi >= 18 ? '#4682B4' : '#5A5A5A' }}></div>
                <div style={{backgroundColor: ffmi >= 20 ? '#4682B4' : '#5A5A5A' }}></div>
                <div style={{backgroundColor: ffmi >= 23 ? '#4682B4' : '#5A5A5A' }}></div>
                <div style={{backgroundColor: ffmi >= 26 ? '#4682B4' : '#5A5A5A' }}></div>
            </div>
        </>
    )
};
