import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ tipPage }: ThemeProps) => ({
    tipPageWrapper: {
        ...tipPage.tipPageWrapper,
        '& img': {
            ...tipPage.tipPageIcon,
        }
    },
    childrenWrapper: {
        maxWidth: '560px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80%',
    }
}));
