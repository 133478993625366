export const MAX_PERCENTS = 100;

export const LOADER_COLORS = {
    bodyColor: '#EB731A',
    bodyColorCheckIconPathFill: '#472309',
    activityColor: '#1375D6',
    activityColorCheckIconPathFill: '#072A4C',
    nutritionColor: '#36B992',
    nutritionColorCheckIconPathFill: '#144134',
};
