import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { ExperimentsKeys } from 'constants/experiments';

import { NextButton } from 'components/next-button';

import { AchievementWithAble } from './achievement-with-able';
import { CortisolTestosterone } from './cortisol-testosterone';

export const Graphs = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const variation = localStorage.getItem(ExperimentsKeys.mg_add_graphs_screen) || '1';

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <>
            {variation === '1' && <AchievementWithAble/>}
            {variation === '2' && <CortisolTestosterone/>}

            <NextButton typographyText={t('graphs-button-next')} onClick={handleClick} />
        </>
    );
};
