import i18n from 'i18next';
import dayjs from 'dayjs';

import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANG = 'en';

export const DEFAULT_VARIABLES = {
    able: 'Able',
};

export const listOfTranslations = {
    'en': { translation: require('./en/en.json') },
    'fr': { translation: require('./fr/fr.json') },
    'fr-FR': { translation: require('./fr/fr.json') },
    'en-GB': { translation: require('./en_GB/en_GB.json') },
    'en-CA': { translation: require('./en_CA/en_CA.json') },
    'en-AU': { translation: require('./en_AU/en_AU.json') },
    'en-IN': { translation: require('./en_IN/en_IN.json') },
    'es': { translation: require('./es/es.json') },
    'es-ar': { translation: require('./es/es.json') },
    'es-bo': { translation: require('./es/es.json') },
    'es-cl': { translation: require('./es/es.json') },
    'es-co': { translation: require('./es/es.json') },
    'es-cr': { translation: require('./es/es.json') },
    'es-do': { translation: require('./es/es.json') },
    'es-ec': { translation: require('./es/es.json') },
    'es-sv': { translation: require('./es/es.json') },
    'es-gt': { translation: require('./es/es.json') },
    'es-hn': { translation: require('./es/es.json') },
    'es-mx': { translation: require('./es/es.json') },
    'es-ni': { translation: require('./es/es.json') },
    'es-pa': { translation: require('./es/es.json') },
    'es-py': { translation: require('./es/es.json') },
    'es-pe': { translation: require('./es/es.json') },
    'es-pr': { translation: require('./es/es.json') },
    'es-es': { translation: require('./es/es.json') },
    'es-uy': { translation: require('./es/es.json') },
    'es-ve': { translation: require('./es/es.json') },
    'es-xl': { translation: require('./es/es.json') }
};

const params = new URLSearchParams(document.location.search);
const language = params.get('language');

i18n.use(initReactI18next).init({
    supportedLngs: [
        'en', 'fr-FR', 'fr', 'en-GB', 'en-CA', 'en-AU', 'en-IN',
        'es', 'es-ar', 'es-bo', 'es-cl', 'es-co', 'es-cr', 'es-do', 'es-ec',
        'es-sv', 'es-gt', 'es-hn', 'es-mx', 'es-ni', 'es-pa', 'es-py', 'es-pe', 'es-pr', 'es-es', 'es-uy', 'es-ve', 'es-xl'],
    returnObjects: true,
    fallbackLng: DEFAULT_LANG,
    lng: language || window.navigator.language?.toLowerCase() || window.navigator.userLanguage?.toLowerCase(),
    resources: {
        ...listOfTranslations,
    },
    interpolation: {
        defaultVariables: {
            ...DEFAULT_VARIABLES,
            escapeValue: false,
        },
    },
    react: {
        transWrapTextNodes: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b'],
    },
    debug: false,
    returnEmptyString: false
});

require(`dayjs/locale/en.js`);

dayjs.locale(i18n.resolvedLanguage);

export default i18n;
