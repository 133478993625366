import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, RatingScale, RatingType } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { RoutePath } from 'routes/route-path.constant';

export const StickWorkoutRoutineRating = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.StickWorkoutRoutineRating];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: RatingType) => {
        setPageValue(answer.value);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: `${t('stick-workout-routine-rating-title')} - ${t('stick-workout-routine-rating-subtitle')}`, value: answer.value },
        });

        pageConfiguration.handleOnPageComplete(answer.label);
    };

    return (
        <>
            <Heading
                title={t('stick-workout-routine-rating-title')}
                subtitle={t('stick-workout-routine-rating-subtitle')} />

            <RatingScale value={pageValue}
                         handleClick={handleClick}
                         ratingGradationInfo={['Strongly Disagree', 'Strongly Agree']} />
        </>
    );
};
