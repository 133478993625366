import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const PastBlockers = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PastBlockers];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'motivation',
            value: t('past-blockers-option-motivation')
        },
        {
            key: 'plan',
            value: t('past-blockers-option-plan')
        },
        {
            key: 'hard',
            value: t('past-blockers-option-hard')
        },
        {
            key: 'time',
            value: t('past-blockers-option-time')
        },
        {
            key: 'coach',
            value: t('past-blockers-option-coach')
        },
        {
            key: 'none',
            value: t('past-blockers-option-none')
        }
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('past-blockers-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                <Trans i18nKey='past-blockers-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='past-blockers-subtitle' />
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    disabled={pageValue[0] === 'none' && v.key !== 'none'}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
