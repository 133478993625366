import React from 'react';

import leftArrow from 'assets/images/svg/left-arrow.svg';

type BackArrowButtonProps = {
    handleBack: () => void;
    backButtonWrapper: string;
    backButtonIconInnerWrapper: string;
    backButton: string;
}

export const BackArrowButton = ({ backButtonWrapper, backButtonIconInnerWrapper, backButton, handleBack }: BackArrowButtonProps): JSX.Element => {

    return (
        <div className={backButtonWrapper}>
            <div className={backButtonIconInnerWrapper} onClick={handleBack}>
                <img className={backButton} src={leftArrow} alt='' />
            </div>
        </div>
    );
};
