import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

export const SpecificDiet = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.SpecificDiet];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const answers: ChoiceType[] = [
        { key: 'not', value: t('specific-diet-option-not') },
        { key: 'lowcarb', value: t('specific-diet-option-lowcarb') },
        { key: 'paleo', value: t('specific-diet-option-paleo') },
        { key: 'keto', value: t('specific-diet-option-keto') },
        { key: 'vegetarian', value: t('specific-diet-option-vegetarian') },
        { key: 'vegan', value: t('specific-diet-option-vegan') },
        { key: 'mediterranean', value: t('specific-diet-option-mediterranean') },
        { key: 'other', value: t('specific-diet-option-other'), userAnswer, setUserAnswer },
    ];

    // Set otherOptionAnswer initial value to avoid using null
    const initialOtherOptionAnswerValue = answers.find(answer => answer.key === 'other') as ChoiceType;
    const [otherOptionAnswer, setOtherOptionAnswer] = useState<ChoiceType>(initialOtherOptionAnswerValue);

    const handleButtonClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        if (answer.key !== 'other') {
            handleNextButtonClick(answer);
        } else {
            setOtherOptionAnswer(answer);
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }
    };

    const handleNextButtonClick = (answer: ChoiceType) => {
        const _userAnswer = answer.key === 'other' ? userAnswer.trim() : '';

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('specific-diet-title'),
                value: `${answer.value} ${_userAnswer}`
            },
        });

        pageConfiguration.handleOnPageComplete({
            value: answer.key,
            userAnswer: _userAnswer
        });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('specific-diet-title')}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    item={v}
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleButtonClick(v)}
                    pageValue={pageValue}
                />
            ))}
            {pageValue === 'other' &&
                <FloatedButton floated={pageValue.length} withGradient>
                    <NextButton onClick={() => handleNextButtonClick(otherOptionAnswer)} disabled={false} />
                </FloatedButton>
            }
        </>
    );
};
