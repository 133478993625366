import React from 'react';

import { TipPage } from 'components/tip-page';

export const StressLevelTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/cup.svg'
            text={{
                description: 'stress-level-tip-description'
            }}
        />
    );
};
