import React from 'react';

import { TipPage } from 'components/tip-page';

export const PhysicalLimitationsTip = () => {

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/medal.svg'
            text={{
                description: 'physical-limitations-tip-title',
            }}
        />
    );
};
