import { createUseStyles } from 'react-jss'
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    agreementText: {
        textAlign: 'left',
        margin: '16px 0 28px 0',

        '& a': {
            textDecoration: 'none',
            color: palette.primary
        }
    }
});
