import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Trans } from 'react-i18next';

import '@dotlottie/player-component';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useLoadingProgress } from 'hooks/use-loading-progress.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ThinProgressBar } from 'components/progress-bar-thin';

// import { ImageTransition } from './image-transition/image-transition';

import { DESIRED_LOOK } from '../desired-look';

import { useStyles } from './styles';

export const CustomPlan = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const {
        bar,
        animationWrapper,
        imageWrapper,
        progressLabel,
    } = useStyles();

    const { pageValue: bodyFat }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyFat],
        defaultValue: 1,
    });

    const { pageValue: desiredLook }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.DesiredLook],
        defaultValue: DESIRED_LOOK.SLIM,
    });

    const [descriptionKey, setDescriptionKey] = useState('custom-plan-description-1');

    const animations = {
        [DESIRED_LOOK.SLIM]: [
            'lottie/custom-plan/slim/5-9.lottie',
            'lottie/custom-plan/slim/10-14.lottie',
            'lottie/custom-plan/slim/15-19.lottie',
            'lottie/custom-plan/slim/20-24.lottie',
            'lottie/custom-plan/slim/25-29.lottie',
            'lottie/custom-plan/slim/30-34.lottie',
            'lottie/custom-plan/slim/35-39.lottie',
            'lottie/custom-plan/slim/40.lottie',
        ],
        [DESIRED_LOOK.SHREDDED]: [
            'lottie/custom-plan/shredded/5-9.lottie',
            'lottie/custom-plan/shredded/10-14.lottie',
            'lottie/custom-plan/shredded/15-19.lottie',
            'lottie/custom-plan/shredded/20-24.lottie',
            'lottie/custom-plan/shredded/25-29.lottie',
            'lottie/custom-plan/shredded/30-34.lottie',
            'lottie/custom-plan/shredded/35-39.lottie',
            'lottie/custom-plan/shredded/40.lottie',
        ],
        [DESIRED_LOOK.BODYBUILDER]: [
            'lottie/custom-plan/bodybuilder/5-9.lottie',
            'lottie/custom-plan/bodybuilder/10-14.lottie',
            'lottie/custom-plan/bodybuilder/15-19.lottie',
            'lottie/custom-plan/bodybuilder/20-24.lottie',
            'lottie/custom-plan/bodybuilder/25-29.lottie',
            'lottie/custom-plan/bodybuilder/30-34.lottie',
            'lottie/custom-plan/bodybuilder/35-39.lottie',
            'lottie/custom-plan/bodybuilder/40.lottie',
        ]
    };

    const pageImages = useBackgroundImageService([animations[desiredLook as DESIRED_LOOK][bodyFat - 1]]);

    const handleClick = useCallback(() => {
        pageConfiguration.handleOnPageComplete();
    }, []);

    const { progress } = useLoadingProgress({
        onDone: handleClick,
    });

    useEffect(() => {
        let key = 'custom-plan-description-1';
        if (progress.percents > 25) {
            key = 'custom-plan-description-2';
        }
        if (progress.percents > 50) {
            key = 'custom-plan-description-3';
        }
        if (progress.percents > 75) {
            key = 'custom-plan-description-4';
        }
        setDescriptionKey(key);
    }, [progress.percents]);

    if (!pageImages[0]) {
        return null;
    }

    return (
        <>
            <div className={animationWrapper}>
                <div className={imageWrapper}>
                    {/*<ImageTransition oldImage={pageImages[0]} newImage={pageImages[1]} />*/}

                    <dotlottie-player src={pageImages[0]} autoplay={true} speed={0.4} />
                </div>
            </div>

            <Typography variant={TypographyVariants.p} className={progressLabel}>
                <Trans i18nKey={descriptionKey} components={[<span></span>]} />
            </Typography>

            <ThinProgressBar progress={progress} className={bar} />
        </>
    );
};
