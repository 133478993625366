import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { pageConfiguration } from 'containers/index';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

import { useStyles } from './styles';

export type PopupData = {
    title: string;
    storageKey: string;
    event: string;
    answers: ChoiceType[];
}

interface ModalContentProps {
    onChosen?: () => void;
    data: PopupData;
}

export const ModalContent: FC<ModalContentProps> = ({ data, onChosen }) => {
    const saveAnswers = useSaveAnswers();
    const { t } = useTranslation();

    const { questionTitle, buttonsWrapper } = useStyles();

    const { title, answers, storageKey, event } = data;

    const { pageValue, setPageValue } = useLocalStorage({ key: storageKey, defaultValue: '' });

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.event(event, t(answer.value));

        saveAnswers({
            key: storageKey,
            itemQuestion: { question: t(title), value: t(answer.value) },
        });

        if (onChosen) {
            setTimeout(onChosen, 100);
        }
    };

    return (
        <div>
            <Typography variant={TypographyVariants.h1} className={questionTitle}>
                {t(title)}
            </Typography>
            <div className={buttonsWrapper}>
                {answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center
                        selected={v.key === pageValue}
                        typographyText={t(v.value)}
                        onClick={() => handleClick(v)}
                        horizontallyAlignedButtons
                    />
                ))}
            </div>
        </div>
    );
};
