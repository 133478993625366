import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { AGES } from 'constants/body-age';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const BodyAge = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyAge];

    const { pageValue: bodyAge, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (age: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-age-title'), value: t(age.value) },
        });
        setPageValue(age.key);

        pageConfiguration.handleOnPageComplete(age.key);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>{t('body-age-title')}</Typography>

            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey={'body-age-subtitle'} />
            </Typography>

            {AGES.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === bodyAge}
                    typographyText={t(v.value)}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
