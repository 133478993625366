import React, { FC } from 'react';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from '../typography';

import { useStyles } from './styles';

export interface RangeSliderProps {
    min: number,
    max: number,
    value: number,
    popupInfo?: string[],
    headInfo?: string[] | number[],
    footerInfo?: string[],
    onChange: (value: number) => {},
}

export const RangeSlider: FC<RangeSliderProps> = ({ value, min, max, popupInfo, headInfo, footerInfo, onChange }) => {
    const {
        sliderComponentWrapper,
        sliderComponent,
        sliderBody,
        sliderDescription,
        sliderDescriptionPosition51,
        sliderDescriptionPosition52,
        sliderDescriptionPosition53,
        sliderDescriptionPosition54,
        sliderDescriptionPosition55,
        sliderDescriptionPosition81,
        sliderDescriptionPosition82,
        sliderDescriptionPosition83,
        sliderDescriptionPosition84,
        sliderDescriptionPosition85,
        sliderDescriptionPosition86,
        sliderDescriptionPosition87,
        sliderDescriptionPosition88,
        range,
        slider,
        sliderHead,
        sliderFooter
    } = useStyles({ maxSlider: max });

    const popupPositionsFor8 = [
        sliderDescriptionPosition81,
        sliderDescriptionPosition82,
        sliderDescriptionPosition83,
        sliderDescriptionPosition84,
        sliderDescriptionPosition85,
        sliderDescriptionPosition86,
        sliderDescriptionPosition87,
        sliderDescriptionPosition88,
    ];

    const popupPositionsFor5 = [
        sliderDescriptionPosition51,
        sliderDescriptionPosition52,
        sliderDescriptionPosition53,
        sliderDescriptionPosition54,
        sliderDescriptionPosition55,
    ];

    const popupPositions = max === 8 ? popupPositionsFor8 : popupPositionsFor5;

    return (
        <div className={sliderComponentWrapper}>

            <div className={sliderComponent}>

                {headInfo &&
                    <div className={sliderHead}>
                        <div>
                            {headInfo.map((info: string | number) => <Typography key={info} variant={TypographyVariants.h3}>{info}</Typography>)}
                        </div>
                    </div>
                }

                <div className={sliderBody}>

                    {popupInfo &&
                        <div className={`${sliderDescription} ${popupPositions[value - 1]}`}>
                            <Typography variant={TypographyVariants.h3}>
                                {popupInfo[value - 1]}
                            </Typography>
                        </div>
                    }

                    <input
                        type='range'
                        min={min}
                        max={max}
                        className={range}
                        value={value}
                        onChange={({ target: { value } }) => {
                            onChange(+value);
                        }}
                    />

                    <div className={slider}></div>
                </div>

                {footerInfo &&
                    <div className={sliderFooter}>
                        {footerInfo.map((info: string) => <Typography key={info} variant={TypographyVariants.h3}>{info}</Typography>)}
                    </div>
                }
            </div>
        </div>
    );
};
