export const ExperimentsKeys = {
    pwaNativeSplitVariation: 'mg_split_native_vs_pwa',
    mg_add_plan_upgrade_screen: 'mg_add_plan_upgrade_screen',
    mg_pwa_split_auto_login: 'mg_pwa_split_auto_login',
    mg_highlighted_block_paywall_page: 'mg_highlighted_block_paywall_page',
    mg_discount_block_on_checkout: 'mg_discount_block_on_checkout',
    mg_delete_motivation_and_specific_event_screens: 'mg_delete_motivation_and_specific_event_screens',
    mg_add_graphs_screen: 'mg_add_graphs_screen',
    mg_personal_statement_screens: 'mg_personal_statement_screens',
    mg_finisher_screens: 'mg_finisher_screens',
    mg_securities_badges: 'mg_securities_badges',
    mg_prediction_graph_on_paywall: 'mg_prediction_graph_on_paywall',
    mg_body_age_first_screen: 'mg_body_age_first_screen',
};
