import { ChoiceType } from 'components/choice-button';

export const AGES: ChoiceType[] = [
    { key: 'underTwenty', value: 'body-age-option-underTwenty' },
    { key: 'twentys', value: 'body-age-option-twenties' },
    { key: 'thirtys', value: 'body-age-option-thirties' },
    { key: 'fortys', value: 'body-age-option-forties' },
    { key: 'fiftys', value: 'body-age-option-fifties' },
    { key: 'sixtyPlus', value: 'body-age-option-sixtyPlus' },
];
