import React, { FC, useEffect, useMemo } from 'react';
import { ValidatedInput } from '../../validated-input';
import { lbsValidationQuery } from './lbs-validation-query';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LABEL = i18n.t('lbs');

type LbsInputProps = {
    value: string;
    onChange: (e: any) => void;
    onError: (error: boolean, key: string) => void;
    lessThen?: number;
    autoFocus?: boolean;
    withEndAdornment?: boolean;
};

export const LbsInput: FC<LbsInputProps> = ({
    value,
    onChange,
    onError,
    autoFocus,
    withEndAdornment = true,
}) => {
    const { t } = useTranslation();

    const props = useMemo(() => ({ label: t('weight'), maxLength: 3 }), []);

    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => onError(false, LABEL), []);

    const endAdornmentLabel = withEndAdornment ? LABEL : '';

    const validationQuery = JSON.parse(JSON.stringify(lbsValidationQuery));

    return (
        <ValidatedInput
            type="number"
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            onError={handleError(LABEL)}
            validationQuery={validationQuery}
            endAdornment={endAdornmentLabel}
            {...props}
        />
    );
};
