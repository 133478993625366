import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ input, typography }: ThemeProps) => ({
    inputWrapper: input.inputWrapper,
    input: input.input,
    inputCloseIconClass: {
        padding: '16px 16px 16px 0',
    },
    inputActiveClass: {
        ...input.inputActiveClass
    },
    inputDisabledClass: {
        cursor: 'not-allowed',
        opacity: .4
    },
    inputErrorClass: {
        ...input.inputErrorClass
    },
    inputLabelClass: {},
    inputLabelActiveClass: typography.inputLabelActive,
    inputLabelErrorClass: typography.inputLabelError,
    inputEndAdornmentClass: {
        marginTop: '20px',
    },
}));

