import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    choiceButtonImages: {
        '& img': {
            paddingTop: '4px',
            borderRadius: '4px',
            background: '#1C1C1C',
        }
    }
});
