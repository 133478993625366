import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export enum WORKOUT_LONG_TIME {
    FIFTEEN = '15-minutes',
    THIRTY = 'half-hour',
    FORTYFIVE = 'quarter-hour',
    HOUR = 'hour',
    FLEXIBLE = 'flexible'
}

export const WORKOUT_LONG_ANSWERS: ChoiceType[] = [
    {
        key: WORKOUT_LONG_TIME.FIFTEEN,
        value: 'workout-long-option-15-minutes',
    },
    {
        key: WORKOUT_LONG_TIME.THIRTY,
        value: 'workout-long-option-half-hour',
    },
    {
        key: WORKOUT_LONG_TIME.FORTYFIVE,
        value: 'workout-long-option-quarter-hour',
    },
    {
        key: WORKOUT_LONG_TIME.HOUR,
        value: 'workout-long-option-hour',
    },
    {
        key: WORKOUT_LONG_TIME.FLEXIBLE,
        value: 'workout-long-option-flexible',
    }
];

export const WorkoutLong = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.WorkoutLong];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-long-title'), value: t(answer.value) },
        });

        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('workout-long-title')}</Typography>

            {WORKOUT_LONG_ANSWERS.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={t(v.value)}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
