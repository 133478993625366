import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    block: {
        '& h2': {
            textAlign: 'inherit',
            margin: '0',
        },
        '& img': {
            width: '100%',
            minHeight: '204px',
        }
    },

    title: {
        textAlign: 'inherit',
        marginBottom: '16px'
    },

    subtitle: {
        textAlign: 'inherit',
        margin: '16px 0'
    },

    descriptions: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },

    '@media only screen and (min-width: 768px)' : {
        title: {
            textAlign: 'center',
            marginBottom: '40px'
        },
        subtitle: {
            textAlign: 'inherit',
            margin: '40px 0 24px'
        },
        descriptions: {
            marginBottom: '20px'
        },
    }
});
