import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    modalWrapper: {
        padding: '0 16px',
    },
    modal: {
        padding: '24px 16px'
    },
    title: {
        margin: '0 0 24px'
    },
    boldText: {
        fontWeight: 600
    },
    subtitle: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#9CC5EE',
        margin: '24px 0 8px'
    },
    listItemWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: '8px'
    },
    listItemIcon: {
        width: '30px',
        height: '30px',
        margin: '0 12px 0 0'
    },
    listItemDescription: {
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        margin: 0
    },
    infoWrapper: {
        background: '#5D391E',
        borderRadius: '8px',
        padding: '12px 16px',
        margin: '24px 0'
    },
    infoText: {
        textAlign: 'left',
        fontSize: '16px',
        lineHeight: '20px'
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'space-between'
    },
    skipButtonWrapper: {
        flexBasis: '35%'
    },
    startButtonWrapper: {
        flexBasis: '60%'
    },
    skipButton: {
        ...nextButton.nextButton,
        minWidth: 'auto',
        height: '100%',
        minHeight: '48px',
        margin: 0,
        background: '#363649',
        color: '#FFFFFF',

        '& span': {
            fontSize: '16px'
        }
    },
    startButton: {
        ...nextButton.nextButton,
        minWidth: 'auto',
        height: '100%',
        minHeight: '48px',
        margin: 0,
        padding: '12px 7.5px',

        '&:active': {
            ...nextButton.nextButtonHovered
        },

        '& span': {
            fontSize: '16px',
            lineHeight: '24px',
            whiteSpace: 'nowrap'
        }
    },
    '@media screen and (min-width: 768px)': {
        modal: {
            maxWidth: '560px',
            padding: '40px 24px'
        },
        title: {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '0 0 32px'
        },
        subtitle: {
            fontSize: '20px',
            lineHeight: '24px',
            margin: '0 0 16px'
        },
        listItemWrapper: {
            marginTop: '16px'
        },
        listItemDescription: {
            fontSize: '20px',
            lineHeight: '28px'
        },
        infoWrapper: {
            padding: '16px',
            margin: '32px 0 40px'
        },
        infoText: {
            fontSize: '20px',
            lineHeight: '28px'
        },
        startButton: {
            padding: '16px',

            '& span': {
                fontSize: '18px'
            }
        },
        skipButton: {
            padding: '15px',

            '& span': {
                fontSize: '18px'
            }
        }
    },
    '@media (hover: hover)': {
        startButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered
            }
        },
    }
}));
