import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

import { SleepHoursAnswers } from '../sleep-hours';

export const SleepHoursTip = () => {

    const description = [SleepHoursAnswers.LESS5, SleepHoursAnswers.FROM5TO6].includes(localStorage.getItem(LocalStorageKeys[RoutePath.SleepHours]) as SleepHoursAnswers)
        ? 'sleep-hours-tip-few-description'
        : 'sleep-hours-tip-normal-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/plane.svg'
            text={{ description }}
        />
    );
};
