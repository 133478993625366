import { createUseStyles } from 'react-jss'
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    marginTop: {
        marginTop: '16px'
    },
    switcherButton: {
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'pointer'
    },
    switcherLink: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 600,
        margin: '16px 0 12px',
        color: palette.primary,
    },
    excitementTextWrapper: {
        padding: '16px',
        marginTop: '32px',
        border: '2px solid #3A3A3A',
        borderRadius: '12px'
    },
    excitementText: {
        fontSize: '14px',
        lineHeight: '18px',
        color: palette.light_grey,
        textAlign: 'left',
        margin: 'unset',

        '&:first-of-type': {
            fontSize: '16px',
            lineHeight: '24px',
            color: palette.white,
            fontWeight: 600,
            margin: '0 0 16px',
        }
    }
});
