import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    sliderComponentWrapper: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column'
    },
    sliderComponent: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: 'transparent',
        width: '100%'
    },
    sliderBody: {
        display: 'flex',
        flexFlow: 'row wrap',
        position: 'relative',
    },
    sliderDescription: {
        display: 'flex',
        justifyContent: 'center',
        left: '3%',
        minWidth: '32px',
        position: 'relative',
        // top: '-100%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
        padding: '4px 8px',
        background: '#333333',
        borderRadius: '4px',
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '-3px',
            width: '0px',
            height: '0px',
            borderStyle: 'solid',
            borderWidth: '7px 6px 0px',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
            borderTopColor: '#333333',
        },
        '& h3': {
            fontSize: '12px',
        }
    },
    slider: {
        position: 'absolute',
        bottom: '10px',
        width: '100%',
        height: '6px',
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(270deg, #EB731A 0%, #57493E 100%)',
        backgroundPosition: 'left center, right center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '100px',
        '&:after': {
            content: "''",
            position: 'relative',
            display: 'flex',
            height: '6px',
            width: '100%',
            backgroundColor: 'transparent',
            backgroundImage: 'linear-gradient(to left, black, 1px, transparent 1px)',
            backgroundSize: ({ maxSlider }: any) => maxSlider === 8 ? 'calc(14.35%)' : 'calc(25%)'
        }
    },
    sliderDescriptionPosition51: {
        left: '8%',
        '&:after': {
            left: '12%'
        }
    },
    sliderDescriptionPosition52: {
        left: '26%'
    },
    sliderDescriptionPosition53: {
        left: '50%'
    },
    sliderDescriptionPosition54: {
        left: '74%'
    },
    sliderDescriptionPosition55: {
        left: '95%',
        '&:after': {
            left: '65%'
        }
    },
    sliderDescriptionPosition81: {
        left: '2%'
    },
    sliderDescriptionPosition82: {
        left: '16%'
    },
    sliderDescriptionPosition83: {
        left: '29.5%'
    },
    sliderDescriptionPosition84: {
        left: '43%'
    },
    sliderDescriptionPosition85: {
        left: '57%'
    },
    sliderDescriptionPosition86: {
        left: '70.5%'
    },
    sliderDescriptionPosition87: {
        left: '84%'
    },
    sliderDescriptionPosition88: {
        left: '98%',
        '&:after': {
            left: '48%'
        }
    },
    range: {
        width: '100%',
        margin: '0px',
        padding: '0px',
        height: '28px',
        background: 'transparent',
        font: '1em / 1 arial, sans-serif',
        zIndex: 1,
        cursor: 'pointer',
        appearance: 'none',
        '&::-webkit-slider-thumb': {
            appearance: 'none',
            '-webkit-appearance': 'none',
            cursor: 'pointer',
            boxSizing: 'border-box',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            background: '#EB731A',
            outline: 'none',
            border: '1px solid #000000',
        },
        '&::-moz-range-thumb': {
            appearance: 'none',
            '-webkit-appearance': 'none',
            cursor: 'pointer',
            boxSizing: 'border-box',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            background: '#EB731A',
            outline: 'none',
            border: '1px solid #000000',
        },
        '&::-ms-thumb': {
            appearance: 'none',
            '-webkit-appearance': 'none',
            cursor: 'pointer',
            boxSizing: 'border-box',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            background: '#EB731A',
            outline: 'none',
            border: '1px solid #000000',
        },
    },
    sliderHead: {
        position: 'absolute',
        width: '100%',
        '& div': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& h3': {
            fontSize: '12px',
            color: '#FFFFFF',
            margin: 'inherit',
        },
    },
    sliderFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h3': {
            fontSize: '12px',
            color: '#FFFFFF',
            margin: 'inherit',
        },
    },
    '@media only screen and (min-width: 768px)' : {
        sliderHead: {
            '& h3': {
                fontSize: '14px',
                lineHeight: '20xp'
            },
        },
        sliderDescription: {
            '& h3': {
                fontSize: '14px',
                lineHeight: '20xp'
            },
        },
        sliderFooter: {
            '& h3': {
                fontSize: '14px',
                lineHeight: '20xp'
            },
        },
        sliderDescriptionPosition51: {
            left: '2%',
            '&:after': {
                left: 'unset'
            }
        },
        sliderDescriptionPosition55: {
            left: '98%',
            '&:after': {
                left: 'unset'
            }
        }
    }
});
