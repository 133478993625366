import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from '../typography';
import { NextButton } from '../next-button';

import { useStyles } from './styles';

export interface TipPageProps {
    imgSrc: string;
    text: {
        description: string,
        values?: string,
        next?: string
    }
    handleClick?: () => void;
}

export const TipPage = ({ imgSrc, text }: TipPageProps) => {
    const { tipPageWrapper, childrenWrapper } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { t } = useTranslation();

    const pageImages = useBackgroundImageService([imgSrc]);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div className={tipPageWrapper}>
            <div className={childrenWrapper}>
                <div>
                    <img src={pageImages[0]} alt="" />

                    <Typography variant={TypographyVariants.tipPage}>
                        {t(text.description, {
                            value: text.values
                        })}
                    </Typography>
                </div>

                <NextButton
                    bottomMargin
                    onClick={handleClick}
                    typographyText={t(text?.next || 'Got it!')}
                />

            </div>
        </div>
    )
};
