import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

interface MobileContentProps {
    isIosDevice: boolean;
    isUserActive: boolean;
    email: string;
    handleClick: () => void;
}

export const MobileContent: FC<MobileContentProps> = ({ isIosDevice, isUserActive, email, handleClick }) => {
    const { t } = useTranslation();

    const {
        welcomeMobileInfoWrapper,
        title,
        subtitle,
        listWrapper,
        list,
        listItem,
        suggestionIfUserExists,
        info,
    } = useStyles();

    const welcomeTitleKey = isIosDevice ? 'welcome-title' : 'welcome-title-android';
    const appName = isIosDevice ? t('welcome-list-text-mobile-download-app-ios-name') : t('welcome-instructions-able-app-name');
    const ableAppName = isIosDevice ? null : t('welcome-instructions-able-app-name');
    const downloadButtonAppName = isIosDevice ? t('welcome-button-next-ios-app-name') : t('welcome-instructions-able-app-name');

    return (
        <div className={welcomeMobileInfoWrapper}>
            <Typography className={`${isIosDevice && title}`} variant={TypographyVariants.h1}
                        typographyNext={!isIosDevice}>
                {!isUserActive && t(welcomeTitleKey)}
                {isUserActive && t('welcome-title-if-user-exists')}
            </Typography>
            {isIosDevice && <Typography className={subtitle} variant={TypographyVariants.h2}>
                {t('welcome-subtitle-ios')}
            </Typography>}

            <div className={listWrapper}>
                <Typography className={listItem} variant={TypographyVariants.h2}>
                    {!isUserActive && t('welcome-instructions-title', { ableAppName })}
                    {isUserActive && t('welcome-subtitle-mobile-if-user-exists')}
                </Typography>
                <ol className={list}>
                    <Typography className={listItem} variant={TypographyVariants.h2}>
                        <li>
                            {t('welcome-list-text-mobile-download-app', { appName })}
                        </li>
                    </Typography>
                    <Typography className={listItem} variant={TypographyVariants.h2}>
                        <li>
                            {!isUserActive && t('welcome-list-text-mobile-open-app', { email })}
                            {isUserActive && t('welcome-list-text-mobile-open-app-if-user-exists', { email })}
                        </li>
                    </Typography>

                    {!isUserActive &&
                        <Typography className={listItem} variant={TypographyVariants.h2}>
                            <li>
                                {t('welcome-list-text-mobile-create-password')}
                            </li>
                        </Typography>
                    }

                </ol>
            </div>

            {isUserActive &&
                <Typography className={suggestionIfUserExists} variant={TypographyVariants.h2}>
                    {t('welcome-text-mobile-suggestion-if-user-exists')}
                </Typography>
            }

            {isIosDevice && <Typography className={info} variant={TypographyVariants.h3}>
                {t('welcome-ios-info')}
            </Typography>}

            <NextButton
                bottomMargin
                onClick={handleClick}
                typographyText={t('welcome-button-next', { downloadButtonAppName })}
            />
        </div>
    );
};
