import { createUseStyles } from 'react-jss'
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    choiceButtonWrapper: {

        '& button': {
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxHeight: '104px',

            '& img': {
                width: '76px',
                height: '88px',
                objectFit: 'contain',
                borderRadius: '4px',
                background: palette.disabled_black,
                paddingTop: '4px'
            }
        },
    },
    choiceButtonsWrapper: {},
    selected: {},
    labelWrapper: {},
    '@media (min-width: 1000px)': {
        choiceButtonsWrapper: {

            '& > div': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '40px',
                marginTop: '20px',

                '& button': {
                    minWidth: '187px',
                    minHeight: '182px',
                    maxHeight: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 0,
                    background: palette.disabled_black,
                    border: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',

                    '& img': {
                        width: '100%',
                        height: '171px',
                        position: 'absolute',
                        top: '13px',
                        background: 'none',
                        borderRadius: 0,
                        paddingTop: 0
                    }
                },

                '& > div:last-child': {

                    '& > div > button > img': {
                        top: '8px'
                    }
                }
            }
        },
        choiceButtonWrapper: {
            border: '3px solid transparent'
        },
        selected: {
            border: `3px solid ${palette.primary}`
        },
        labelWrapper: {
            width: '100%',
            height: '31px',
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            background: palette.dark_grey,
            zIndex: 1,

            '& > span': {
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '11px',
                letterSpacing: '0.72px'
            }
        }
    }
});
