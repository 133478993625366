import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const PersonalizedProgram = () => {
    const { animationPageWrapper, textWrapper, animatedText, first, second, third } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const [index, setIndex] = useState(0);

    const { pageValue: desiredLook }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.DesiredLook],
        defaultValue: '',
    });

    useEffect(() => {
        const timeouts = [
            2500,
            4000,
            3000
        ];
        let counter = 1;
        let timeoutId: ReturnType<typeof setTimeout>;

        const timeout = (duration: number) => {
            timeoutId = setTimeout(function() {
                setIndex(counter);
                if (++counter === text.length + 1) {
                    handleClick();
                    clearTimeout(timeoutId);
                } else {
                    timeout(timeouts[counter-1]);
                }
            }, duration);
        }

        timeout(timeouts[counter-1]);

        return () => clearTimeout(timeoutId);
    }, []);

    const text = [
        <Typography
            className={`${animatedText} ${first}`}
            variant={TypographyVariants.h1}
            typographyNext
        >
            <Trans i18nKey="personalized-program-text-based" />
        </Typography>,
        <Typography
            onAnimationStart={() => pageConfiguration.event('MenFinisher2Viewed')}
            className={`${animatedText} ${second}`}
            variant={TypographyVariants.h1}
            typographyNext
        >
            <Trans i18nKey="personalized-program-text-personalized" />
        </Typography>,
        <Typography
            onAnimationStart={() => pageConfiguration.event('MenFinisher3Viewed')}
            className={`${animatedText} ${third}`}
            variant={TypographyVariants.h1}
        >
            <Trans i18nKey="personalized-program-text-weight" values={{ desiredLook }} />
        </Typography>,
    ];

    return (
        <div className={animationPageWrapper}>
            <div className={textWrapper}>{text[index]}</div>
        </div>
    );
};
