import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const IntermittentFasting = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.IntermittentFasting];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });


    const answers: ChoiceType[] = [
        {
            key: 'yes',
            value: t('intermittent-fasting-option-yes'),
        },
        {
            key: 'no',
            value: t('intermittent-fasting-option-no'),
        },
        {
            key: 'not-now',
            value: t('intermittent-fasting-option-not-now'),
        },
    ];

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('intermittent-fasting-title'), value: t(answer.value) },
        });
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('intermittent-fasting-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
