import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { BaseContext } from 'context/base.context';
import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { getUserFFMI } from 'services/body-plan-calculator.service';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { UpgradeCustomerSubscription } from './upgrade-customer-subscription';

import { LocalStorageKeys } from 'constants/local-storage';
import { CHARGE_PERIOD_UNIT, PLANCODE_PRIMERIO } from 'constants/trial-pay';
import { ExperimentsKeys } from 'constants/experiments';
import { APP_TYPE_APP, APP_TYPE_PWA } from 'constants/app-name';

import { Content } from 'pages/upgrade-plan/content';

export type PLANS_FOR_UPGRADE_TYPE = {
    key: string;
    amount: number;
    currencyCode: string;
    chargePeriod: number;
    chargeSubscriptionPeriodUnit: string;
}

enum CHARGE_PERIOD {
    ONE_MONTH = 1,
    THREE_MONTHS = 3,
    SIX_MONTHS = 6,
    TWELVE_MONTHS = 12
}

const getChargePeriodInDays = (chargePeriodInMonths: number) => {
    return dayjs().add(chargePeriodInMonths, 'month').diff(dayjs(), 'day');
};

const PLANS_FOR_UPGRADE: Record<string, PLANS_FOR_UPGRADE_TYPE> = {
    [CHARGE_PERIOD.ONE_MONTH]: {
        key: 'able-1-month-for-105-USD-Every-1-month',
        amount: 10500,
        currencyCode: 'USD',
        chargePeriod: getChargePeriodInDays(CHARGE_PERIOD.ONE_MONTH),
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
    },
    [CHARGE_PERIOD.THREE_MONTHS]: {
        key: 'able-3-month-for-304-USD-Every-3-months',
        amount: 30400,
        currencyCode: 'USD',
        chargePeriod: getChargePeriodInDays(CHARGE_PERIOD.THREE_MONTHS),
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
    },
    [CHARGE_PERIOD.SIX_MONTHS]: {
        key: 'able-6-month-for-595-USD-Every-6-months',
        amount: 59500,
        currencyCode: 'USD',
        chargePeriod: getChargePeriodInDays(CHARGE_PERIOD.SIX_MONTHS),
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
    },
    [CHARGE_PERIOD.TWELVE_MONTHS]: {
        key: 'able-12-month-for-910-USD-12-months',
        amount: 91000,
        currencyCode: 'USD',
        chargePeriod: getChargePeriodInDays(CHARGE_PERIOD.TWELVE_MONTHS),
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
    },
};

export const UpgradePlan = () => {
    const navigate = useNavigate();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { pageValue: plan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TrialPay],
        defaultValue: PLANCODE_PRIMERIO[0],
    });

    const userFFMI = useMemo(() => getUserFFMI(), []);

    const upgradePlan = useMemo(() => {
        let planId = CHARGE_PERIOD.ONE_MONTH;
        if (userFFMI < 23) {
            planId = CHARGE_PERIOD.THREE_MONTHS;
        }
        if (userFFMI < 20) {
            planId = CHARGE_PERIOD.SIX_MONTHS;
        }
        if (userFFMI < 18) {
            planId = CHARGE_PERIOD.TWELVE_MONTHS;
        }
        return PLANS_FOR_UPGRADE[planId];
    }, [userFFMI]);

    useEffect(() => {
        localStorage.setItem(LocalStorageKeys[RoutePath.UpgradePlan], JSON.stringify(upgradePlan));
    }, []);

    const [showSkipTrialModalWindow, setSkipTrialModalWindow] = useState(false);
    const [loading, setLoading] = useState(false);

    const pwaNativeSplitVariationExp = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariation) === '1';

    const upgradeSubscription = (event: string) => {
        const startTime = performance.now();

        setLoading(true);

        UpgradeCustomerSubscription(upgradePlan)
            .then(({ customerId, subscriptionId }) => {
                pageConfiguration.event(event, {
                    period: upgradePlan.chargePeriod,
                    user_id: customerId,
                    subscription_id: subscriptionId,
                    user_app: pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP
                });
            }).catch((e) => {
                pageConfiguration.event('MenPlanUpgradePurchaseError', {
                    period: upgradePlan.chargePeriod,
                    error: e?.type,
                    message: e?.message,
                });
            }).finally(() => {
                const endTime = performance.now();
                const executionTimeMs = endTime - startTime;
                pageConfiguration.event('MenPlanUpgradePurchaseExecutionTime', {
                    executionTimeMs: executionTimeMs
                });

                setLoading(false);

                navigate(`..${RoutePath.Congrats}`);
            });
    };

    const handleUpgradeSubscription = () => {
        upgradeSubscription('MenPlanUpgradePurchased');
    };

    const handleSkipUpgradeSubscription = () => {
        pageConfiguration.event('MenPlanUpgradeSkipped');
        setSkipTrialModalWindow(true);
    };

    const handleSkipTrialModalWindow = () => {
        pageConfiguration.event('MenPlanUpgradePopUpSkipClicked');
        navigate(`..${RoutePath.Congrats}`);
    };

    const handleUpgradeTrialModalWindow = () => {
        setSkipTrialModalWindow(false);
        upgradeSubscription('MenPlanUpgradePopUpStartClicked');
    };

    useEffect(() => {
        if (upgradePlan?.key) {
            pageConfiguration.event('MenPlanUpgradeViewed', { period: upgradePlan.chargePeriod });
        }
    }, [upgradePlan]);

    return (
        <Content loading={loading}
                 showSkipTrialModalWindow={showSkipTrialModalWindow}
                 handleSkipTrialModalWindow={handleSkipTrialModalWindow}
                 handleUpgradeTrialModalWindow={handleUpgradeTrialModalWindow}
                 handleSkipUpgradeSubscription={handleSkipUpgradeSubscription}
                 handleUpgradeSubscription={handleUpgradeSubscription}

                 plan={plan}
                 newPlan={upgradePlan}
        />
    );
};
