import React, {useContext, useEffect, useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { BODY_FAT_PERCENTAGE } from 'constants/body-fat';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { RangeSlider } from 'components/range-slider';

import { useStyles } from './styles';

export const BodyFat = () => {
    const { t } = useTranslation();

    const { bodyImageWrapper, title, bodyImage, description } = useStyles();

    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyFat];

    const { pageValue: bodyFat, setPageValue: setBodyFat }: any = useLocalStorage({
        key: pageKey,
        defaultValue: 1,
    });

    // @TODO: Replace later to provided images
    const images = [
        'webp/body-fat/5-9.webp', 'webp/body-fat/10-14.webp',
        'webp/body-fat/15-19.webp', 'webp/body-fat/20-24.webp',
        'webp/body-fat/25-29.webp', 'webp/body-fat/30-34.webp',
        'webp/body-fat/35-39.webp', 'webp/body-fat/40.webp',
    ];

    const pageImages = useBackgroundImageService(images);

    const [selectedImages, setSelectedImages] = useState(pageImages[bodyFat - 1]);

    useEffect(() => {
        if (pageImages?.length) {
            setSelectedImages(pageImages[bodyFat - 1]);
        }
    }, [pageImages?.length, bodyFat]);

    const handleClick = () => {
        const data = BODY_FAT_PERCENTAGE[bodyFat - 1];

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-fat-title'), value: data.label },
        });

        pageConfiguration.handleOnPageComplete(bodyFat - 1);
    };

    return (
        <>
            <div className={bodyImageWrapper}>
                {selectedImages && <img src={selectedImages} className={bodyImage} alt='Your body' />}
            </div>

            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey={'body-fat-title'} />
            </Typography>

            <RangeSlider
                value={bodyFat}
                min={1}
                max={8}
                popupInfo={BODY_FAT_PERCENTAGE.map(v => v.label)}
                footerInfo={[BODY_FAT_PERCENTAGE[0].label, BODY_FAT_PERCENTAGE[BODY_FAT_PERCENTAGE.length - 1].label.replace('+', '>')]}
                onChange={setBodyFat} />

            <div className={description}>
                <Typography variant={TypographyVariants.h3}>
                    <Trans i18nKey={'body-fat-description'} />
                </Typography>
            </div>

            <NextButton onClick={handleClick} />
        </>
    );
};
